class Controller {
  constructor(constantsService, $timeout, products, orgPerspectives) {
    'ngInject';

    this.constantsService = constantsService;
    this.$timeout = $timeout;
    this.products = products;
    this.orgPerspectives = orgPerspectives;
  }

  $onInit() {
    this.remoteDirectory = 'products/' + this.product.$id + '/externalFiles';
    this.products.updateLastViewed(this.product.$id);
    this.missingFilesAlerts = [];
    this.findMissingFiles();
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.categories = _.omitBy(fileTypes, fc => fc.companyLevel);
    });
  }

  findMissingFiles() {
    if (!this.isReadOnly) {
      this.products.getMissingFiles(this.organization.$id, this.product.$id).then(missingFileRecs => {
        this.missingFilesAlerts = [];
        _.each(missingFileRecs, missingRec => {
          this.missingFilesAlerts.push(`You are missing the following files requested by <b>${missingRec.requesterName}</b>: ${missingRec.missingFiles.join(', ')}`);
        });
      });
    }
  }

  removeAlert(index) {
    this.missingFilesAlerts.splice(index, 1);
  }
}

module.exports = Controller;
