/* eslint-disable angular/on-watch */
'use strict';
require('./_css');

let moduleName = 'foodReady',
  ngModule = angular.module(moduleName, [
    require('./vendor')(moduleName),
    require('./auth')(moduleName),
    require('./users')(moduleName),
    require('./administration')(moduleName),
    require('./products')(moduleName),
    require('./company')(moduleName),
    require('./ingredients')(moduleName),
    require('./email')(moduleName),
    require('./events')(moduleName),
    require('./billing')(moduleName),
    require('./notifications')(moduleName),
    require('./marketplace')(moduleName),
    require('./onboarding')(moduleName),
    require('./file_portal')(moduleName),
    require('./questionnaires')(moduleName),
    require('./message')(moduleName),
    require('./reports')(moduleName),
    require('./recall')(moduleName),
    require('./operations')(moduleName),
    require('./checklists')(moduleName),
    require('./message')(moduleName),
    require('./food_products')(moduleName),
    require('./project_writing')(moduleName),
    require('./verification')(moduleName),
    require('./task_manager')(moduleName),
    'ui.tinymce'
  ])
    .config(
      function (
        $urlRouterProvider,
        FB_API,
        FB_AUTH_DOMAIN,
        FB_URL,
        $httpProvider,
        $templateRequestProvider,
        $sceProvider
      ) {
        'ngInject';

        if (!firebase.apps.length) {
          firebase.initializeApp({
            apiKey: FB_API,
            authDomain: FB_AUTH_DOMAIN,
            databaseURL: FB_URL
          });
        } else {
          firebase.app();
        }

        $sceProvider.enabled(false);
        $urlRouterProvider.otherwise(($injector) => {
          let $state = $injector.get('$state'),
            $firebaseAuth = $injector.get('$firebaseAuth'),
            authentication = $injector.get('authentication'),
            User = $injector.get('User'),
            LOCAL_DEVELOPMENT = $injector.get('LOCAL_DEVELOPMENT'),
            V2_URL = $injector.get('V2_URL'),
            $window = $injector.get('$window');

          $firebaseAuth().$requireSignIn()
            .then((authUser) => {
              if (!authentication.user && authUser) {
                authentication.user = new User(authUser);
              }

              authentication.user.$loaded()
                .then(() => {
                  if (LOCAL_DEVELOPMENT) {
                    $state.go('user.dashboard');
                  } else {
                    $window.open(`${V2_URL}/dashboard`, '_self');
                  }
                });
            });
        });

        $httpProvider.interceptors.push('cfHttpInterceptor');
        $templateRequestProvider.httpOptions({ _isTemplate: true });
      })
    .run(function ($document, $rootScope, cfpLoadingBar, $http, $state, $log, $timeout, idle, $window, $location, authentication,
      drift, profitwell, V2_URL, LOCAL_DEVELOPMENT) {
      'ngInject';
      let loggedInUid;

      firebase.auth().onAuthStateChanged((authData) => {
        loggedInUid = loggedInUid || (authData ? authData.uid : null);
        if (!authData && $state.current.name) {
          // idle.unwatch();
          if ($state.current.name !== 'signup.begin' &&
            $state.current.name !== 'filePortal.intro' &&
            $state.current.name !== 'questionnaire' &&
            $state.current.name !== 'authentication.resetPassword' &&
            $state.current.name !== 'authentication.fbActionUrl') {

            if (LOCAL_DEVELOPMENT) {
              $state.go('authentication.signin', _.get($state, 'params.reason') && { reason: $state.params.reason });
              // workaround for refresh tabs sync
            } else if(!localStorage.getItem('idToken') && !localStorage.getItem('refreshToken')) {
              $window.open(`${V2_URL}/signin`, '_self');
            }
          }

          if ($window.localStorage) {
            $window.localStorage.removeItem('session.fbToken');
          }

          $log.info(`FB onAuth event. User logged out: ${ loggedInUid || '(No UID).' }`);
          loggedInUid = null;
        } else if (authData) {
          // idle.watch();
          if (typeof $window.dataLayer !== 'undefined') {
            $window.dataLayer.push({
              event: 'logged_in',
              userId: authData.uid,
              name: `${authData.displayName}` || authData.name,
              email: authData.email
            });
          }
          drift.userLoggedIn(authData);
          profitwell.userLoggedIn(authData);
          $log.info(`FB onAuth event. User authenticated: ${ _.get(authData, 'uid', null) }`);
        } else if (!$state.current.name && !$window.location.href.includes('/portal/intro')) {
          if (LOCAL_DEVELOPMENT) {
            $state.go('authentication.signin', _.get($state, 'params.reason') && { reason: $state.params.reason });
          } else {
            $window.open(`${V2_URL}/signin`, '_self');
          }
        }
      });

      let curState, startTime;

      $rootScope.$on('$stateChangeStart', (event, state) => {
        cfpLoadingBar.start();
        curState = state.name;
        $rootScope.pendingTranstionToName = state.name;
        startTime = new Date().getTime();

        /**
         * Settings module has been migrated to V2 (react)
         * For local development allow access for old routes
         */
        if (!LOCAL_DEVELOPMENT && state.name && state.name.includes('user.settings')) {
          const parts = state.name.split('.');
          const lastPart = parts[parts.length - 1];

          if (lastPart === 'profile') {
            $window.open(`${V2_URL}/user/settings/account`, '_self');
          } else if(lastPart === 'group') {
            $window.open(`${V2_URL}/user/settings/userGroups`, '_self');
          } else {
            $window.open(`${V2_URL}/user/settings/${lastPart}`, '_self');
          }
        }
      });

      $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
        let params = { 'page_path': $state.href(toState.name, toParams) };

        if (loggedInUid) {
          params['user_id'] = loggedInUid;
        }
        // gtag.config(params);
        drift.stateChanged(toState.name);
        cfpLoadingBar.complete();
        $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;

        if (($state.current.name === 'signup.begin' || $state.current.name === 'authentication.signin') &&
          firebase.auth().currentUser) {
          authentication.logout();
        } else if (_.get(authentication, 'user.orgContext.perspective') === 'pendingSignup' &&
          $state.current.name !== 'signup.plan' &&
          !$state.includes('filePortal')) {
          $state.go('signup.plan');
        }

        if (authentication.user && _.get(authentication, 'user.orgContext.perspective') !== 'pendingSignup' &&
          (!authentication.user.orgContext.hasOwnProperty('companyName') ||
            (!authentication.user.orgContext.hasOwnProperty('phone') && !authentication.user.hasOwnProperty('phone')))) {
          $state.go('onboarding.firstPlan');
        }
      });

      $rootScope.$on('$viewContentLoaded', () => {
        if (startTime) {
          let startTimeSave = startTime,
            stateSave = curState;

          $timeout(() => {
            let endTime = new Date().getTime(),
              timeDiff = endTime - startTimeSave;

            $log.log('TIMING_LOG', {
              state: stateSave,
              durationMs: timeDiff
            });
          }, 0);

          startTime = curState = undefined;
        }
      });

      let oldWarn = console.warn;

      // Capture Firebase warnings and feed into the $log error handler so they are properly logged in ES.
      console.warn = function (warn) {
        if (_.isString(warn) && warn.startsWith('FIREBASE WARNING')) {
          $log.error(warn);
        } else {
          oldWarn(warn);
        }
      };

      // Need to create a default route on the server to enable HTML5 mode
      // $locationProvider.html5Mode(true).hashPrefix('!');
    });

require('./_config')(ngModule);
require('./_services')(ngModule);
require('./_directives')(ngModule);
require('./_components')(ngModule);
require('./_decorators')(ngModule);
require('./_constants')(ngModule);
require('./_filters')(ngModule);
require('./_run')(ngModule);

//Then define the init function for starting up the application
angular.element(document).ready(function () {
  //Fixing facebook bug with redirect
  if (window.location.hash === '#_=_') {
    window.location.hash = '#!';
  }

  //Then init the app
  angular.bootstrap(document, [moduleName], { strictDi: true });
});
