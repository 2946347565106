class Controller {
  constructor(constantsService, $timeout, products, orgPerspectives, $uibModal, $firebaseObject,
    growl, utils, confirmDeleteModal, S3, $q) {
    'ngInject';

    this.constantsService = constantsService;
    this.$timeout = $timeout;
    this.products = products;
    this.orgPerspectives = orgPerspectives;
    this.$uibModal = $uibModal;
    this.$firebaseObject = $firebaseObject;
    this.growl = growl;
    this.utils = utils;
    this.confirmDeleteModal = confirmDeleteModal;
    this.S3 = S3;
    this.$q = $q;

    this.fileTypeIcons = [
      {type: 'application/pdf', icon: 'far fa-file-pdf'},
      {type: 'text/csv', icon: 'far fa-file-csv'},
      {type: /sheet/, icon: 'far fa-file-excel'},
      {type: /doc/, icon: 'far fa-file-word'},
      {type: /image/, icon: 'far fa-file-image'},
    ];
  }

  $onInit() {
    this.remoteDirectory = 'products/' + this.product.$id + '/externalFiles';
    this.products.updateLastViewed(this.product.$id);
    this.missingFilesAlerts = [];
    this.constantsService.get('fileTypes').then(fileTypes => {
      this.categories = _.omitBy(fileTypes, fc => fc.companyLevel);
    });
  }

  edit(item, categoryId) {
    return this.$uibModal.open({
      component: 'cfMedia',
      controllerAs: 'vm',
      backdrop: 'static',
      resolve: {
        item: () => this.$firebaseObject(item ? this.files.$ref().child(item.$id) : this.files.$ref().push()).$loaded(),
        remoteDirectory: () => this.remoteDirectory,
        media: () => this.files,
        organization: () => this.productOrganization,
        categories: () => this.categories,
        warning: () => this.warning,
        askInPlan: () => true,
        chosenCategory: () => categoryId
      }
    }).result.then(() => {
      this.growl.success('Files ' + (item ? 'updated' : 'added') + ' successfully.', {});
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to create new file'));
  }

  remove(item) {
    this.confirmDeleteModal(_.truncate(item.name, {length: 30}))
        .then(() => {
          this.files.$remove(item)
              .then(() => {
                return this.S3.deleteFile(this.productOrganization.$id, item.key)
                    .catch((err) => {
                      this.files.$add(_.pick(item, ['url', 'key', 'name', 'size', 'type']));
                      return this.$q.reject(err);
                    });
              })
              .then(() => {
                this.growl.success('File successfully removed.');
              })
              .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete file.'));
        });
  }

  toDateString(date) {
    return date ? moment(date).format('MM-DD-YYYY') : 'Unknown';
  }

  open(file) {
    this.S3.openSignedUrlInNewTab(this.productOrganization.$id, file.key, null,
        this.S3.parseKey(file.key).productId);
  }

  categoryName(fileType) {
    let category = _.find(this.categories, c => c.id === fileType);

    return category ? category.text : '';
  }

  getIcon(fileType) {
    let typeObj = _.find(this.fileTypeIcons, f => _.isString(f.type) ? f.type === fileType : f.type.test(fileType));

    return typeObj ? typeObj.icon : 'far fa-file';
  }

  changeFileInPlan(file) {
    this.$timeout(() => this.files.$save(file).then(() => this.growl.success('File Saved'))
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to save file.')));
  }
}

module.exports = Controller;

