class Controller {
  constructor(
    $timeout,
    $uibModal,
    $log,
    $firebaseObject,
    $state,
    $document,
    faSvgIcons,
    $q,
    confirmModal,
    $window,
    growl,
    authorization,
    confirmDeleteModal,
    products,
    ingredientService,
    GO_LICENSE,
    utils,
    organizations,
    deviceDetector,
    sopService,
    $rootScope,
    planTypes,
    orgPerspectives,
    ingredients,
    featureIntroModal,
    SAMPLE_ORGANIZATION_ID,
    equipmentService,
    controlsService,
    hazardsService,
    $stateParams,
    choiceModal,
    $scope,
    InventoryItemsSearch
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.$firebaseObject = $firebaseObject;
    this.$state = $state;
    this.$window = $window;
    this.$document = $document;
    this.growl = growl;
    this.authorization = authorization;
    this.$q = $q;
    this.confirmDeleteModal = confirmDeleteModal;
    this.products = products;
    this.faSvgIcons = faSvgIcons;
    this.confirmModal = confirmModal;
    this.ingredientService = ingredientService;
    this.GO_LICENSE = GO_LICENSE;
    this.utils = utils;
    this.organizations = organizations;
    this.deviceDetector = deviceDetector;
    this.sopService = sopService;
    this.$rootScope = $rootScope;
    this.planTypes = planTypes;
    this.orgPerspectives = orgPerspectives;
    this.ingredients = ingredients;
    this.featureIntroModal = featureIntroModal;
    this.SAMPLE_ORGANIZATION_ID = SAMPLE_ORGANIZATION_ID;
    this.equipmentSvc = equipmentService;
    this.ingredientsSvc = ingredientService;
    this.controlsSvc = controlsService;
    this.hazardsSvc = hazardsService;
    this.$stateParams = $stateParams;
    this.choiceModal = choiceModal;
    this.$scope = $scope;
    this.InventoryItemsSearch = InventoryItemsSearch;

    this.loadingTemplate = false;
    this.hidePalette = false;

    this.colorPalette = {
      NORMAL_FILL: '#f8f8f8',
      HIGHLIGHTED_PATH_FILL: '#c4e3ed',
      NORMAL_STROKE: '#ddd',
      SELECTED_FILL: '#e6e6e6',
      SELECTED_STROKE: '#adadad',
      NORMAL_LINK: 'gray',
      TEXT_STROKE: '#0f3e0f',
      TEXT_STROKE_HIGHLIGHTED: 'white',

      // Control Panel Colors
      CONTROL_COLOR: '#98e698',
      DARK_CONTROL_COLOR: '#196719',
      MUTED_CONTROL_COLOR: '#eafaea',
      HOVER_CONTROL_COLOR: '#73dd73',

      // Hazard Panel Colors
      // HAZARD_COLOR: '#f8d7da',
      // DARK_HAZARD_COLOR: '#c32231',
      // MUTED_HAZARD_COLOR: '#fbe9eb',
      // HOVER_HAZARD_COLOR: '#f4c2c6',
      HAZARD_COLOR: '#ffd6b3',
      DARK_HAZARD_COLOR: '#ff7800',
      MUTED_HAZARD_COLOR: '#fff1e6',
      HOVER_HAZARD_COLOR: '#ffad66',

      // CCP Star Burst Colors
      STAR_COLOR: '#FFFF66',
      DARK_STAR_COLOR: '#ffff1a',
      MUTED_STAR_COLOR: '#ffffe5',
    };

    this.fonts = {
      tooltip: '13px "Helvetica Neue"',
      node: '8pt helvetica, arial, sans-serif',
      nodeHighlighted: 'bold 10pt helvetica, arial, sans-serif',
    };
  }

  $onInit() {
    this.isReadOnlyOrMobile = this.isReadOnly || this.deviceDetector.isMobile();
    this.tips = {
      addLinks: {
        id: 'addLink',
        selector: '.circular-menu',
        title: 'Tip: Add a New Link',
        placement: 'left-bottom',
        contentHtml:
          'Add a link between two process steps by grabbing one of the four circles in ' +
          'any step and dragging the arrow to another step.',
      },

      addHazard: {
        id: 'radialHazard',
        selector: '#radialHazard',
        width: 500,
        placement: 'left-bottom',
        title: 'Identify Hazards in Your Process Steps',
        contentHtml:
          'Select any process step that contains a potential hazard. After the menu opens, click the ' +
          '<i class="fa fa-exclamation-triangle fa-fw"></i> button to open the Hazards Analysis page for that step.',
      },
    };
    this.isHaccp = this.product.planType === this.planTypes.HACCP_PLAN.id;

    this.defineGoRectangles();

    this.equipmentMap = {};
    this.equipmentPromise = this.$q.all(
      _.map(this.product.equipment, (val, id) =>
        this.equipmentSvc.$get(id).then((equipment) => this.equipmentMap[id] = equipment)
      )
    );

    this.ingredientsMap = {};
    this.ingredientsPromise = {};

    if(this.product?.ingredients){
      const ingredientIds = Object.keys(this.product.ingredients).join(',');
      const searchObject = new this.InventoryItemsSearch().itemIds(ingredientIds);
      this.ingredientsPromise = searchObject.search();
    }

    this.controlsPromise = this.products.$getControls(this.product.$id).then(($controls) => {
      this.controlsMap = this.createStepControlMap($controls);

      return $controls;
    });

    this.$q
      .all([
        this.products.$getEquipment(this.product.$id),
        this.products.$getIngredients(this.product.$id),
        this.controlsPromise,
        this.equipmentPromise,
        this.ingredientsPromise,
      ])
      .then(([$equipment, $ingredients, $controls]) => {
        this.$equipment = $equipment;
        this.$ingredients = $ingredients;
        this.$controls = $controls;

        $equipment.$watch((e) => {
          if (e.event === 'child_added') {
            this.equipmentSvc
              .$get(e.key)
              .then((equipment) => this.equipmentMap[e.key] = equipment)
              .then(() =>
                _.each(_.get(this.product.equipment, `${e.key}.processSteps`, {}), (step, stepId) =>
                  this.refreshNode(stepId)
                )
              );
          } else {
            let stepsToRefresh = _.get(this.product.equipment, `${e.key}.processSteps`, {});

            this.$timeout(() =>
              _.assign(stepsToRefresh, _.get(this.product.equipment, `${e.key}.processSteps`, {}))
            ).then(() => _.each(stepsToRefresh, (step, stepId) => this.refreshNode(stepId)));
          }
        });

        $ingredients.$watch((e) => {
          if (e.event === 'child_added') {
            this.ingredientsSvc
              .get(e.key)
              .then((ingredient) => this.ingredientsMap[e.key] = ingredient)
              .then(() =>
                _.each(_.get(this.product.ingredients, `${e.key}.processSteps`, {}), (step, stepId) =>
                  this.refreshNode(stepId)
                )
              );
          } else {
            let stepsToRefresh = _.get(this.product.ingredients, `${e.key}.processSteps`, {});

            this.$timeout(() =>
              _.assign(stepsToRefresh, _.get(this.product.ingredients, `${e.key}.processSteps`, {}))
            ).then(() => _.each(stepsToRefresh, (step, stepId) => this.refreshNode(stepId)));
          }
        });

        let updateStepControls = _.debounce((stepId) => {
          this.controlsMap = this.createStepControlMap(this.$controls);

          this.$timeout(() => this.refreshNode(stepId));
        }, 100);

        this.$controls.$watch((e) => {
          let controlChanged = this.$controls.$getRecord(e.key);

          if (!controlChanged) {
            let stepId = _.findKey(this.controlsMap, (val) => _.some(val, (c) => c.$id === e.key));

            if (stepId) {
              controlChanged = {stepId};
              this.controlsMap[stepId].shift();
            }
          }
          if (controlChanged) {
            updateStepControls(controlChanged.stepId);
          }
        });
        let prevIntroducedAt = _.reduce(
          this.$hazards,
          (map, hazard) => {
            map[hazard.$id] = hazard.introductionStep;
            return map;
          },
          {}
        );

        this.$hazards.$watch((e) =>
          this.$timeout(() => {
            let stepsToUpdate = _.reduce(
              this.$controls,
              (stepIds, c) => {
                if (c.hazards[e.key]) {
                  stepIds[c.stepId] = true;
                }
                return stepIds;
              },
              {}
            );

            const addUpdateStep = function (stepId) {
              if (stepId) {
                stepsToUpdate[stepId] = true;
              }
            };

            if (e.event === 'child_removed') {
              addUpdateStep(prevIntroducedAt[e.key]);
              prevIntroducedAt[e.key] = null;
            } else {
              let hazard = this.$hazards.$getRecord(e.key);

              // If 'introduced At step' changed, add old and new steps to list;
              if (!prevIntroducedAt[e.key]) {
                addUpdateStep(hazard.introductionStep);
              } else if (prevIntroducedAt[e.key] !== hazard.introductionStep) {
                addUpdateStep(hazard.introductionStep);
                addUpdateStep(prevIntroducedAt[e.key]);
              }

              prevIntroducedAt[e.key] = hazard.introductionStep;
            }

            _.each(stepsToUpdate, (val, stepId) => this.refreshNode(stepId));
          })
        );
      });
  }

  $onDestroy() {
    this.saveState();

    if (this.$equipment) {
      this.$equipment.$destroy();
    }
    if (this.$ingredients) {
      this.$ingredients.$destroy();
    }
    if (this.$controls) {
      this.$controls.$destroy();
    }
  }

  $postLink() {
    this.$q.all([this.equipmentPromise, this.ingredientsPromise, this.controlsPromise]).then(() => {
      let modelData = this.processSteps.map((step) => this.hydrateNodeData(step));

      this.locationLess = !_.some(modelData, (r) => r.loc);
      this.stepsModel = new go.GraphLinksModel(
        modelData,
        _.map(this.processLinks, (link) => {
          return {key: link.$id, from: link.from, to: link.to};
        })
      );

      this.stepsModel.linkKeyProperty = 'key';
      this._go = go.GraphObject.make;

      if (this.GO_LICENSE) {
        go.licenseKey = this.GO_LICENSE;
      }

      this.diagram = this.defineDiagram();

      try {
        this.setNodeTemplates(this.diagram);
        this.setLinkTemplate(this.diagram);
        this.initToolManager(this.diagram);
      } catch (err) {
        this.$log.error('An error occurred making the goJs templates.', err);
        throw err;
      }

      this.diagram.addLayerBefore(this._go(go.Layer, {name: 'tipLayer'}), this.diagram.findLayer('Foreground'));
      this.restoreState();
      this.startEventListeners();

      // initialize the Palette that is on the left side of the page
      this.palette = this.definePalette();

      this.processSteps.$watch((e) => {
        if (e.event === 'child_changed') {
          this.refreshNode(e.key, false, false);

        } else if (e.event === 'child_removed') {
          let nodeData = this.diagram.model.findNodeDataForKey(e.key);

          this.diagram.model.startTransaction('deleteStep');
          this.diagram.model.removeNodeData(nodeData);
          this.diagram.model.commitTransaction('deleteStep');
        } else if (e.event === 'child_added' && !this.loadingTemplate) {
          let promise = _.get(this.addNodeDeferred, 'promise') || this.$q.resolve();

          promise
            .then(() => {
              if (!this.diagram.model.findNodeDataForKey(e.key)) {
                let $step = this.processSteps.$getRecord(e.key);

                this.diagram.model.startTransaction('addStep');
                this.diagram.model.addNodeData(this.hydrateNodeData($step));
                this.diagram.model.commitTransaction('addStep');
                this.diagram.select(this.diagram.findNodeForKey($step.$id));
                this.scrollToStep($step.$id);

                delete this.addNodeDeferred;
              }
            })
            .catch((err) => this.utils.defaultErrorHandler('Unable to add step', err));
        }
      });

      this.processLinks.$watch((e) => {
        if (e.event === 'child_added') {
          let $link = this.processLinks.$getRecord(e.key),
            linkData =
              $link.from && $link.to && _.find(this.diagram.model.linkDataArray, {from: $link.from, to: $link.to});

          if (!linkData) {
            this.diagram.model.startTransaction('addLink');
            this.diagram.model.addLinkData({key: e.key, from: $link.from, to: $link.to});
            this.diagram.model.commitTransaction('addLink');
          } else {
            this.diagram.model.setKeyForLinkData(linkData, e.key);
          }

          // Sometimes this gets rapid fired and results in multiple growl messages. Debounce subsequent calls.
          _.debounce(
            () =>
              this.organizations.setMilestoneAchieved(
                this.product.organizationId,
                this.organizations.milestones.DIAGRAM_STARTED
              ),
            3000,
            {leading: true, trailing: false}
          )();
        } else if (e.event === 'child_changed') {
          let $link = this.processLinks.$getRecord(e.key),
            linkData = this.diagram.model.findLinkDataForKey(e.key);

          if (linkData) {
            this.diagram.model.startTransaction('updateLink');
            if (linkData.from !== $link.from) {
              this.diagram.model.setFromKeyForLinkData(linkData, $link.from);
            }

            if (linkData.to !== $link.to) {
              this.diagram.model.setToKeyForLinkData(linkData, $link.from);
            }
            this.diagram.model.commitTransaction('updateLink');
          }
        } else if (e.event === 'child_removed') {
          let linkData = this.diagram.model.findLinkDataForKey(e.key);

          if (linkData) {
            this.diagram.model.startTransaction('deleteLink');
            this.diagram.model.removeLinkData(linkData);
            this.diagram.model.commitTransaction('deleteLink');
          }
        }
      });
    });
  }

  defineDiagram() {
    let diagram = this._go(go.Diagram, 'myDiagramDiv', {
      model: this.stepsModel,
      allowDrop: !this.isReadOnlyOrMobile,
      contentAlignment: go.Spot.TopCenter,
      scrollMode: go.Diagram.InfiniteScroll,
      'toolManager.hoverDelay': 300,
      allowZoom: true,
      minScale: 0.6,
      maxScale: 1.4,
      'draggingTool.dragsLink': !this.isReadOnlyOrMobile,
      'linkingTool.portGravity': 20,
      'linkingTool.isUnconnectedLinkValid': true,
      'linkingTool.direction': go.LinkingTool.ForwardsOnly,
      'relinkingTool.portGravity': 20,
      'relinkingTool.isUnconnectedLinkValid': true,
      'relinkingTool.fromHandleArchetype': this._go(go.Shape, 'Diamond', {
        segmentIndex: 0,
        cursor: 'pointer',
        desiredSize: new go.Size(8, 8),
        fill: 'tomato',
        stroke: 'darkred',
      }),
      'relinkingTool.toHandleArchetype': this._go(go.Shape, 'Diamond', {
        segmentIndex: -1,
        cursor: 'pointer',
        desiredSize: new go.Size(8, 8),
        fill: 'darkred',
        stroke: 'tomato',
      }),
      'linkReshapingTool.handleArchetype': this._go(go.Shape, 'Diamond', {
        desiredSize: new go.Size(7, 7),
        fill: 'lightblue',
        stroke: 'deepskyblue',
      }),
      click: () => {
        if (this.showingTip) {
          this.closeTips();
        }

        this.$timeout(() => this.showLegend = false);
      },
      grid: this._go(
        go.Panel,
        'Grid',
        this._go(go.Shape, 'LineH', {stroke: 'lightgray', strokeWidth: 0.2}),
        this._go(go.Shape, 'LineH', {stroke: 'gray', strokeWidth: 0.2, interval: 10}),
        this._go(go.Shape, 'LineV', {stroke: 'lightgray', strokeWidth: 0.2}),
        this._go(go.Shape, 'LineV', {stroke: 'gray', strokeWidth: 0.2, interval: 10})
      ),
      //todo: If we can sync ALL UI changes with the underlying processStep model, we can turn this back on.
      //'undoManager.isEnabled': true
    });

    // keep the diagram from auto-scrolling when focused
    diagram.doFocus = () => {
      let x = this.$window.scrollX || this.$window.pageXOffset,
        y = this.$window.scrollY || this.$window.pageYOffset;

      go.Diagram.prototype.doFocus();
      this.$window.scrollTo(x, y);
    };

    this.$rootScope.$on(
      'productUiDividerMoved',
      _.debounce(() => diagram.requestUpdate(), 200)
    );
    this.$rootScope.$on('updateDiagram', () => {
      this.diagram.requestUpdate();
      this.palette.requestUpdate();
    });
    this.$scope.$watch(
      'vm.$stateParams.hazardId',
      (hazardId) => {
        let hazards = hazardId && hazardId.split(',');

        if (hazards) {
          this.diagram.model.startTransaction('updateNode');
          this.clearHighlights(false, false);
          _.each(hazards, (hazardId) => this.highlightHazard(hazardId));
          this.diagram.model.commitTransaction('updateNode');
        } else {
          this.clearHighlights(true, null);
        }
      },
      true
    );

    // This is a bit of a hack to try to stop the sporadic 'blank diagram' issue when returning to the diagram.
    this.$scope.$watch(
      'vm.$stateParams.activeView',
      (activeView) => {
        if (activeView === 'diagram') {
          this.$timeout(() => this.$rootScope.$broadcast('updateDiagram'));
        }
      },
      true
    );

    // For some reason the node locations aren't available. Use auto-arrange to set them initially.
    if (this.locationLess) {
      this.arrangeLayout(true);
      this.locationLess = false;
    }

    return diagram;
  }

  definePalette() {
    return this._go(go.Palette, 'paletteDiv', {
      layout: this._go(go.GridLayout, {spacing: new go.Size(20, 20)}),
      maxSelectionCount: 1,
      nodeTemplate: this._go(
        go.Node,
        'Auto',
        {
          locationSpot: go.Spot.Center,
          selectionAdorned: false,
          cursor: 'move',
          deletable: false,
        },
        this._go(go.Shape, 'RoundedRectangle', {name: 'titleShape', fill: '#f9f9f9', strokeWidth: 1, stroke: '#ddd'}),
        this._go(
          go.TextBlock,
          {
            name: 'titleText',
            stroke: '#555',
            margin: 7,
            maxSize: new go.Size(160, NaN),
            wrap: go.TextBlock.WrapFit,
          },
          new go.Binding('text')
        )
      ),
      // simplify the link template, just in this Palette
      linkTemplate: this._go(
        go.Link,
        {
          locationSpot: go.Spot.Center,
          cursor: 'move',
          selectionAdornmentTemplate: this._go(
            go.Adornment,
            'Link',
            {locationSpot: go.Spot.Center},
            this._go(go.Shape, {isPanelMain: true, fill: null, stroke: 'deepskyblue', strokeWidth: 0}),
            this._go(
              go.Shape, // the arrowhead
              {toArrow: 'standard', stroke: null, fill: 'deepskyblue'}
            )
          ),
        },
        {routing: go.Link.AvoidsNodes, curve: go.Link.JumpOver, corner: 5, toShortLength: 4},
        new go.Binding('points'),
        this._go(
          go.Shape, // the link path shape
          {isPanelMain: true, strokeWidth: 2, stroke: 'gray'}
        ),
        this._go(
          go.Shape, // the arrowhead
          {toArrow: 'standard', stroke: null, fill: 'gray'}
        )
      ),

      // specify the contents of the Palette
      model: new go.GraphLinksModel(
        [
          {
            text: 'New Step',
            hasEquipment: false,
            hasIngredients: false,
            isCcp: false,
            elementType: 'node',
          },
        ],
        [
          // the Palette also has a disconnected Link, which the user can drag-and-drop
          {
            points: new go.List(go.Point).addAll([
              new go.Point(0, 0),
              new go.Point(0, 20),
              new go.Point(75, 20),
              new go.Point(75, 40),
            ]),
            elementType: 'link',
          },
        ]
      ),
    });
  }

  setNodeTemplates(diagram) {
    let nodeProperties = {
      name: 'Node',
      background: 'transparent',
      locationSpot: go.Spot.Center,
      selectionAdorned: false,
      selectionChanged: (e) => this.onNodeSelectionChanged(e),
      cursor: 'pointer',
      deletable: false,
      movable: !this.isReadOnlyOrMobile,
    };

    diagram.nodeTemplateMap.add('', this.makeNode(nodeProperties));
    diagram.nodeTemplateMap.add('control', this.makeNode(nodeProperties, {control: true}));
    diagram.nodeTemplateMap.add('hazard', this.makeNode(nodeProperties, {hazardIntroduced: true}));
    diagram.nodeTemplateMap.add(
      'hazardAndControl',
      this.makeNode(nodeProperties, {control: true, hazardIntroduced: true})
    );
  }

  makeNode(nodeProperties, options) {
    options = options || {};
    let primaryShape = 'RoundedRectangle';
    let primaryShapeOptions = _.assign(
      {
        name: 'titleShape',
        strokeWidth: 1,
        minSize: new go.Size(90, 0),
        fill: this.colorPalette.NORMAL_FILL,
        stroke: this.colorPalette.NORMAL_STROKE,
      },
      options.control || options.hazardIntroduced ?
        {} :
        {
          portId: '',
          fromLinkable: false,
          toLinkable: true,
          cursor: 'pointer',
        }
    );

    let panelsArray = [
      this._go(
        go.Panel,
        'Auto',
        {
          click: (e, node) => {
            this.$timeout(() => this.showLegend = false);

            if (this.diagram.selection.count > 1 || !this.selectedStep) {
              return;
            }

            this.showQuickMenu(node);
          },
        },
        this._go(
          go.Shape,
          primaryShape,
          primaryShapeOptions,
          new go.Binding('fill', 'highlightStep', (val) =>
            val ? this.colorPalette.HIGHLIGHTED_PATH_FILL : this.colorPalette.NORMAL_FILL
          )
        ),
        this._go(
          go.TextBlock,
          {
            name: 'titleText',
            stroke: '#555',
            margin: 10,
            maxSize: new go.Size(160, NaN),
            wrap: go.TextBlock.WrapFit,
          },
          new go.Binding('text')
        )
      ),
    ];

    if (options.control) {
      panelsArray.push(
        this._go(
          go.Panel,
          'Auto',
          {
            stretch: go.GraphObject.Fill,
            click: (e, node) => {
              const stepId = node.part.data.key;
              const stepControls = this.controlsMap[stepId] || [];

              if (!stepControls.length) {
                return;
              }
              let quickListObj = {
                header: 'Controls',
                items: _.map(stepControls, (c) => {
                  let text = _.capitalize(c.type);

                  if (this.isHaccp && c.type === 'process') {
                    text = 'CCP';
                  }
                  return {
                    text: `<div><b>${text}: </b><small>${c.measures || '<i>No Measures</i>'}</small></div>`,
                    id: c.$id,
                  };
                }),
                listClass: 'controls-quick-list',
                editFn: (itemId) => {
                  this.isQuickListOpen = false;
                  this.onEditControl({controlId: itemId});
                },
                removeFn: ($event, itemId) => {
                  $event.stopPropagation();
                  this.isQuickListOpen = false;
                  this.onRemoveControl({controlId: itemId})
                    .then(() => this.growl.success('Control removed.'))
                    .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove control.'));
                },
                highlightFn: (itemId) => {
                  this.pauseViewportListener = true;
                  _.each(this.product.controls[itemId].hazards, (val, hazardId) => this.highlightHazardNodes(hazardId));
                  this.$timeout(() => {
                    this.pauseViewportListener = false;
                  }, 200);
                },
              };

              this.showQuickList(node.findObject('controlShape'), quickListObj);
            },
            mouseEnter: (e, obj) => {
              if (!this.$stateParams.hazardId) {
                this.highlightShape(obj, 'controlShape', this.colorPalette.HOVER_CONTROL_COLOR);
              }
            },
            mouseLeave: (e, obj) => {
              if (!this.$stateParams.hazardId) {
                this.highlightShape(obj, 'controlShape', this.colorPalette.CONTROL_COLOR);
              }
            },
          },
          this._go(
            go.Shape,
            'RoundedRectangle',
            {
              name: 'controlShape',
              strokeWidth: 1,
              stroke: this.colorPalette.NORMAL_STROKE,
              fill: this.colorPalette.CONTROL_COLOR,
            },
            new go.Binding('fill', 'highlightControl', (val) => {
              if (val === null) {
                return this.colorPalette.CONTROL_COLOR;
              } else if (val) {
                return this.colorPalette.DARK_CONTROL_COLOR;
              } else {
                return this.colorPalette.MUTED_CONTROL_COLOR;
              }
            })
          ),
          this._go(
            go.TextBlock,
            {name: 'controlText', text: 'Control', margin: 4, font: this.fonts.node},
            new go.Binding('stroke', 'highlightControl', (val) =>
              val ? this.colorPalette.TEXT_STROKE_HIGHLIGHTED : this.colorPalette.TEXT_STROKE
            ),
            new go.Binding('font', 'highlightControl', (val) => val ? this.fonts.nodeHighlighted : this.fonts.node)
          )
        )
      );
    }

    if (options.hazardIntroduced) {
      panelsArray.unshift(
        this._go(
          go.Panel,
          'Auto',
          {
            stretch: go.GraphObject.Fill,
            click: (e, node) => {
              const stepId = node.part.data.key;
              const stepHazards = _.pickBy(this.product.hazards, (h) => h.introductionStep === stepId) || [];

              if (_.isEmpty(stepHazards)) {
                return;
              }
              let quickListObj = {
                header: 'Hazards',
                items: _.map(stepHazards, (h, id) => {
                  const type = h.groupId ? _.get(this.product, `hazardGroups[${h.groupId}].type`) : h.type;
                  const name = h.groupId ? _.get(this.product, `hazardGroups[${h.groupId}].name`) : h.name;

                  return {
                    text: `<div><i class="${this.hazardsSvc.hazardTypes[type].icon}"></i> ${name}</div>`,
                    id,
                  };
                }),
                listClass: 'hazards-quick-list',
                editFn: (itemId) => {
                  this.isQuickListOpen = false;
                  this.onEditHazard({hazard: _.assign(this.product.hazards[itemId], {$id: itemId})});
                },
                removeFn: ($event, itemId) => {
                  $event.stopPropagation();
                  this.isQuickListOpen = false;
                  this.onRemoveHazard({hazard: _.assign(this.product.hazards[itemId], {$id: itemId})})
                    .then(() => this.growl.success('Hazard removed.'))
                    .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to remove hazard.'));
                },
                highlightFn: (itemId) => {
                  this.pauseViewportListener = true;
                  this.highlightControlNodes(itemId);
                  this.$timeout(() => {
                    this.pauseViewportListener = false;
                  }, 200);
                },
              };

              this.showQuickList(node.findObject('hazardShape'), quickListObj);
            },
            mouseEnter: (e, obj) => {
              if (!this.$stateParams.hazardId) {
                this.highlightShape(obj, 'hazardShape', this.colorPalette.HOVER_HAZARD_COLOR);
              }
            },
            mouseLeave: (e, obj) => {
              if (!this.$stateParams.hazardId) {
                this.highlightShape(obj, 'hazardShape', this.colorPalette.HAZARD_COLOR);
              }
            },
          },
          this._go(
            go.Shape,
            'RoundedRectangle',
            {
              name: 'hazardShape',
              strokeWidth: 1,
              stroke: this.colorPalette.NORMAL_STROKE,
              fill: this.colorPalette.HAZARD_COLOR,
            },
            new go.Binding('fill', 'highlightHazard', (val) => {
              if (val === null) {
                return this.colorPalette.HAZARD_COLOR;
              } else if (val) {
                return this.colorPalette.DARK_HAZARD_COLOR;
              } else {
                return this.colorPalette.MUTED_HAZARD_COLOR;
              }
            })
          ),
          this._go(
            go.TextBlock,
            {
              name: 'hazardText',
              font: this.fonts.node,
              stroke: this.colorPalette.TEXT_STROKE,
              text: 'Hazard',
              margin: 4,
            },
            new go.Binding('stroke', 'highlightHazard', (val) =>
              val ? this.colorPalette.TEXT_STROKE_HIGHLIGHTED : this.colorPalette.TEXT_STROKE
            ),
            new go.Binding('font', 'highlightHazard', (val) => val ? this.fonts.nodeHighlighted : this.fonts.node)
          )
        )
      );
    }

    return this._go(
      go.Node,
      'Spot',
      nodeProperties,
      new go.Binding('position', 'loc', go.Point.parse),
      new go.Binding('layerName', 'layer'),
      this._go(
        go.Panel,
        'Horizontal',
        {portId: '', fromLinkable: false, toLinkable: true, cursor: 'pointer'},
        panelsArray
      ),

      this.makeCcpBurst(),
      this.makeIngredientsCircle(),
      this.makeIngredientsIcon(),
      this.makeEquipmentCircle(),
      this.makeEquipmentIcon(),

      this.makePort('T', new go.Spot(0.5, 0, 0, 0)),
      this.makePort('L', new go.Spot(0, 0.5, 0, 0)),
      this.makePort('R', new go.Spot(1, 0.5, 0, 0)),
      this.makePort('BL', new go.Spot(0.25, 1, 0, 0)),
      this.makePort('BR', new go.Spot(0.75, 1, 0, 0)),
      {
        // handle mouse enter/leave events to show/hide the ports
        mouseEnter: (e, node) => {
          this.showSmallPorts(node, true);
        },
        mouseLeave: (e, node) => {
          this.showSmallPorts(node, false);
        },
      }
    );
  }

  makeCcpBurst() {
    const tipText = this.isHaccp ?
      'CCP: This step is classified as a CCP because it controls at least ' +
        'one significant hazard and the "Control Type" is NOT one of ' +
        '"Other" or "Prerequisite Program (SOP)".' :
      'PC: This step is classified as a Preventive Control ' +
        'because it controls at least one significant hazard and the "Control Type" is NOT "other".';
    const toolTip = this._go(
      go.Adornment,
      'Auto',
      {isShadowed: true, shadowBlur: 15, shadowColor: 'lightgray', shadowOffset: new go.Point(0, 3)},
      this._go(go.Shape, 'RoundedRectangle', {fill: '#fff', stroke: '#ddd', strokeWidth: 1}),
      this._go(go.TextBlock, {
        margin: 5,
        stroke: '#212529',
        font: this.fonts.tooltip,
        width: 400,
        wrap: go.TextBlock.WrapFit,
        text: tipText,
      })
    );

    return this._go(
      go.Panel,
      'Auto',
      {alignment: new go.Spot(1, 1, 0, 0), toolTip},
      this._go(
        go.Shape,
        'EightPointedBurst',
        {name: 'ccpShape', strokeWidth: 1, stroke: '#CCCC00', width: 36, height: 36},
        new go.Binding('fill', 'highlightControl', (val) => {
          if (val === null) {
            return this.colorPalette.STAR_COLOR;
          } else if (val) {
            return this.colorPalette.DARK_STAR_COLOR;
          } else {
            return this.colorPalette.MUTED_STAR_COLOR;
          }
        })
      ),
      this._go(go.TextBlock, {
        name: 'ccpText',
        font: '6.5pt helvetica, arial, sans-serif',
        stroke: '#0f3e0f',
        text: this.isHaccp ? 'CCP' : 'PC',
        margin: 4,
      }),
      new go.Binding('visible', 'isCcp')
    );
  }

  makeIngredientsCircle() {
    return this._go(
      go.Shape,
      {
        figure: 'Circle',
        stroke: '#4cae4c',
        fill: '#72c02c',
        width: 15,
        height: 15,
        alignment: new go.Spot(0, 0.9, 0, 0),
      },
      new go.Binding('visible', 'hasIngredients'),
      {toolTip: this.getGoIngredientTooltip()}
    );
  }

  makeIngredientsIcon() {
    let leafGeo = go.Geometry.parse(go.Geometry.fillPath(this.faSvgIcons.faLeaf));

    return this._go(
      go.Shape,
      {geometry: leafGeo, width: 8, height: 8, strokeWidth: 0, fill: '#fff', alignment: new go.Spot(0, 0.9, 0, 0)},
      new go.Binding('visible', 'hasIngredients'),
      {toolTip: this.getGoIngredientTooltip()}
    );
  }

  makeEquipmentCircle() {
    return this._go(
      go.Shape,
      {
        figure: 'Circle',
        stroke: '#644523',
        fill: '#9c8061',
        width: 15,
        height: 15,
        alignment: new go.Spot(1, 0, 0, 0),
      },
      new go.Binding('visible', 'hasEquipment'),
      {toolTip: this.getGoEquipmentTooltip()}
    );
  }

  makeEquipmentIcon() {
    let hazardGeo = go.Geometry.parse(go.Geometry.fillPath(this.faSvgIcons.faConveyorBelt));

    return this._go(
      go.Shape,
      {
        geometry: hazardGeo,
        width: 8,
        height: 8,
        strokeWidth: 0,
        fill: '#fff',
        alignment: new go.Spot(1, 0, 0, 0),
      },
      new go.Binding('visible', 'hasEquipment'),
      {toolTip: this.getGoEquipmentTooltip()}
    );
  }

  setLinkTemplate(diagram) {
    diagram.linkTemplate = this._go(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 5,
        toShortLength: 4,
        relinkableFrom: !this.isReadOnlyOrMobile,
        relinkableTo: !this.isReadOnlyOrMobile,
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Top,
        deletable: !this.isReadOnlyOrMobile,
        click: () => {
          this.$timeout(() => {
            this.showLegend = false;
          });
        },

        // mouse-overs subtly highlight links:
        mouseEnter: function (e, link) {
          link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)';
        },
        mouseLeave: function (e, link) {
          link.findObject('HIGHLIGHT').stroke = 'transparent';
        },
      },
      new go.Binding('points').makeTwoWay(),
      new go.Binding('layerName', 'layer'),
      this._go(go.Shape, {isPanelMain: true, strokeWidth: 8, stroke: 'transparent', name: 'HIGHLIGHT'}),
      this._go(
        go.Shape,
        {isPanelMain: true, stroke: this.colorPalette.NORMAL_LINK, strokeWidth: 2},
        new go.Binding('stroke', 'linkHighlighted', (val) =>
          val ? this.colorPalette.HIGHLIGHTED_PATH_FILL : this.colorPalette.NORMAL_LINK
        )
      ),
      this._go(
        go.Shape,
        {toArrow: 'standard', stroke: null, fill: this.colorPalette.NORMAL_LINK},
        new go.Binding('fill', 'linkHighlighted', (val) =>
          val ? this.colorPalette.HIGHLIGHTED_PATH_FILL : this.colorPalette.NORMAL_LINK
        )
      )
    );
  }

  stepHasIngredients($step) {
    let hasIngredients = false;

    _.each(this.product.ingredients, (ing) => {
      if (_.some(ing.processSteps, (step, stepId) => stepId === $step.$id)) {
        hasIngredients = true;

        return false;
      }
    });

    return hasIngredients;
  }

  stepHasEquipment($step) {
    let hasEquipment = false;

    _.each(this.product.equipment, (equip) => {
      if (_.some(equip.processSteps, (step, stepId) => stepId === $step.$id)) {
        hasEquipment = true;

        return false;
      }
    });

    return hasEquipment;
  }

  getStepItemToolTip($step, items, itemMap) {
    let toolTipText = '';
    let index = 1;

    _.each(items, (item, itemId) => {
      _.each(item.processSteps, (step, stepId) => {
        if (stepId === $step.$id) {
          toolTipText += toolTipText ?
            `\n${index++}. ${_.get(itemMap, `[${itemId}].name`, '')}` :
            `${index++}. ${_.get(itemMap, `[${itemId}].name`, '')}`;
        }
      });
    });

    return toolTipText;
  }

  getControlToolTip($step, hazards) {
    let toolTipText = 'Hazards Controlled:';

    const stepControls = this.controlsMap[$step.$id] || [];

    _.each(stepControls, (control) => {
      _.each(control.hazards, (val, hazardId) => {
        let hazardName = _.get(hazards, `[${hazardId}].name`, '');

        toolTipText += `\n\nHazard: ${hazardName}\nControl: ${_.upperCase(control.type)}`;
      });
    });

    return toolTipText;
  }

  getHazardToolTip($step, hazards) {
    let toolTipText = 'Hazards Introduced:\n';

    _.each(hazards, (h) => {
      if (h.introductionStep === $step.$id) {
        toolTipText += `\n${h.groupId ? this.product.hazardGroups[h.groupId].name : h.name}`;
      }
    });

    return toolTipText;
  }

  hydrateNodeData($step) {
    const stepControls = this.controlsMap[$step.$id] || [];
    const isCcp = _.some(
      stepControls,
      (c) =>
        this.controlsSvc.isCritical(c) &&
        _.some(c.hazards, (val, hazardId) => _.get(this.product, `hazards[${hazardId}].isSignificant`, false))
    );
    const isControl = !_.isEmpty(stepControls);
    const introducesHazard = _.some(this.product.hazards, (h) => h.introductionStep === $step.$id);
    let category;

    if (introducesHazard && isControl) {
      category = 'hazardAndControl';
    } else if (isControl) {
      category = 'control';
    } else if (introducesHazard) {
      category = 'hazard';
    } else {
      category = '';
    }

    return {
      key: $step.$id,
      text: `${$step.number ? $step.number + '.' : ''} ${$step.name}`,
      hasIngredients: this.stepHasIngredients($step),
      ingredientsText: this.getStepItemToolTip($step, this.product.ingredients, this.ingredientsMap),
      controlTipText: this.getControlToolTip($step, this.product.hazards),
      hazardTipText: this.getHazardToolTip($step, this.product.hazards),
      hasEquipment: this.stepHasEquipment($step),
      equipmentText: this.getStepItemToolTip($step, this.product.equipment, this.equipmentMap),
      loc: $step.loc,
      isCcp,
      category,
    };
  }

  refreshNode(stepId) {
    const stepControls = this.controlsMap[stepId];
    let step = this.processSteps.$getRecord(stepId),
      nodeData = this.diagram.model.findNodeDataForKey(stepId);
    const isControl = !_.isEmpty(stepControls);
    const isCcp = _.some(
      stepControls,
      (c) =>
        this.controlsSvc.isCritical(c) &&
        _.some(c.hazards, (val, hazardId) => _.get(this.product, `hazards[${hazardId}].isSignificant`, false))
    );
    const introducesHazard = _.some(this.product.hazards, (h) => h.introductionStep === stepId);
    let category;

    if (introducesHazard && isControl) {
      category = 'hazardAndControl';
    } else if (isControl) {
      category = 'control';
    } else if (introducesHazard) {
      category = 'hazard';
    } else {
      category = '';
    }

    if (!step) {
      this.$log.error('Step not found. Cannot refresh node in diagram.', {stepIdStr: stepId});
      return;
    }

    this.diagram.model.startTransaction('updateNode');
    this.diagram.model.setDataProperty(nodeData, 'text', `${step.number ? step.number + '.' : ''} ${step.name}`);
    this.diagram.model.setDataProperty(nodeData, 'loc', step.loc);
    this.diagram.model.setDataProperty(nodeData, 'isCcp', isCcp);
    this.diagram.model.setDataProperty(nodeData, 'category', category);
    this.diagram.model.setDataProperty(nodeData, 'hasIngredients', this.stepHasIngredients(step));
    this.diagram.model.setDataProperty(
      nodeData,
      'ingredientsText',
      this.getStepItemToolTip(step, this.product.ingredients, this.ingredientsMap)
    );
    this.diagram.model.setDataProperty(nodeData, 'controlTipText', this.getControlToolTip(step, this.product.hazards));
    this.diagram.model.setDataProperty(nodeData, 'hazardTipText', this.getHazardToolTip(step, this.product.hazards));
    this.diagram.model.setDataProperty(nodeData, 'hasEquipment', this.stepHasEquipment(step));
    this.diagram.model.setDataProperty(
      nodeData,
      'equipmentText',
      this.getStepItemToolTip(step, this.product.equipment, this.equipmentMap)
    );

    this.diagram.model.commitTransaction('updateNode');
  }

  getGoEquipmentTooltip() {
    return this._go(
      go.Adornment,
      'Auto',
      {isShadowed: true, shadowBlur: 15, shadowColor: 'lightgray', shadowOffset: new go.Point(0, 3)},
      this._go(go.Shape, 'RoundedRectangle', {fill: '#fff', stroke: '#ddd', strokeWidth: 1}),
      this._go(
        go.TextBlock,
        {margin: 5, stroke: '#212529', font: this.fonts.tooltip, width: 400, wrap: go.TextBlock.WrapFit},
        new go.Binding('text', 'equipmentText')
      )
    );
  }

  getGoIngredientTooltip() {
    return this._go(
      go.Adornment,
      'Auto',
      {isShadowed: true, shadowBlur: 15, shadowColor: 'lightgray', shadowOffset: new go.Point(0, 3)},
      this._go(go.Shape, 'RoundedRectangle', {fill: '#fff', stroke: '#ddd', strokeWidth: 1}),
      this._go(
        go.TextBlock,
        {margin: 5, stroke: '#212529', font: this.fonts.tooltip, width: 400, wrap: go.TextBlock.WrapFit},
        new go.Binding('text', 'ingredientsText')
      )
    );
  }

  getTooltip(text) {
    return this._go(
      go.Adornment,
      'Auto',
      {isShadowed: true, shadowBlur: 15, shadowColor: 'lightgray', shadowOffset: new go.Point(0, 3)},
      this._go(go.Shape, 'RoundedRectangle', {fill: '#fff', stroke: '#ddd', strokeWidth: 1}),
      this._go(
        go.TextBlock,
        {margin: 5, stroke: '#212529', font: this.fonts.tooltip, width: 400, wrap: go.TextBlock.WrapFit},
        new go.Binding('text', text)
      )
    );
  }

  initToolManager(diagram) {
    let tool = diagram.toolManager.draggingTool;

    tool.doDragOver = (pt, obj) => {
      go.DraggingTool.prototype.doDragOver.call(tool, pt, obj);
      this.clearQuickMenus();
    };
  }

  startEventListeners() {
    let canvas = this.$document.find('#myDiagramDiv > canvas');

    canvas.mousedown(() => {
      this.mouseUpDeferred = this.$q.defer();
    });

    canvas.mouseup(() => {
      if (this.mouseUpDeferred) {
        this.mouseUpDeferred.resolve();
      }
    });

    this.diagram.addDiagramListener(
      'ViewportBoundsChanged',
      () => !this.pauseViewportListener && this.$timeout(() => this.diagram.clearSelection())
    );
    this.diagram.addDiagramListener('ChangedSelection', () => {
      if (this.diagram.selection.count > 1) {
        this.clearQuickMenus();
      }
    });

    if (!this.isReadOnlyOrMobile) {
      this.diagram.addDiagramListener('LinkRelinked', (evt) => this.onRelinkLink(evt));
      this.diagram.addDiagramListener('LinkDrawn', (evt) => this.onNewLink(evt));
      this.diagram.addDiagramListener('SelectionDeleted', (evt) => this.onLinkDeleted(evt));
      this.diagram.addDiagramListener('SelectionMoved', (evt) => this.persistCoordinates(evt.subject));
      this.diagram.addDiagramListener('ExternalObjectsDropped', (evt) => {
        for (let it = evt.subject.iterator; it.next();) {
          let data = _.get(it, 'value.data');
          let node = it.value;

          if (_.get(data, 'elementType') === 'link') {
            let data = _.get(it, 'value.data'),
              toStepId = _.get(data, 'to'),
              fromStepId = _.get(data, 'from');

            if (toStepId && fromStepId) {
              this.processLinks.$add({from: fromStepId, to: toStepId});
            }
          } else if (_.get(data, 'elementType') === 'node') {
            this.addNodeDeferred = this.$q.defer();

            return this.$newStep(_.get(data, 'text'), go.Point.stringify(_.get(it, 'value.part.position'))).then(
              ($step) => {
                this.diagram.model.startTransaction('updateKey');
                this.diagram.model.setKeyForNodeData(data, $step.$id);
                this.diagram.model.commitTransaction('updateKey');
                delete $step.name;
                this.editStep($step)
                  .then(() => {
                    node.isSelected = true;
                    this.showQuickMenu(node);
                  })
                  .finally(() => this.addNodeDeferred.resolve());
              }
            );
          }
        }
      });
    }
  }

  $newStep(stepName, location) {
    let $step = this.$firebaseObject(this.processSteps.$ref().ref.push());

    $step.name = stepName;

    if (location) {
      $step.loc = location;
    } else {
      $step.loc = this.diagram.viewportBounds.position.x + 200 + ' ' + (this.diagram.viewportBounds.position.y + 100);
      $step.loc = this.getUniqueNodeLocation(this.diagram, $step.loc);
    }

    return $step.$save().then(() => $step);
  }

  adjustLocationToAvoidCollision(location) {
    this.diagram.nodes.each((part) => {
      if (part.data.loc === location) {
        const p = go.Point.parse(location);

        p.x += 30;
        p.y += 30;
        location = go.Point.stringify(p);
      }
    });

    return location;
  }

  getUniqueNodeLocation(diagram, proposedLocation) {
    while (true) {
      let adjustedLocation = this.adjustLocationToAvoidCollision(proposedLocation);

      if (_.isEqual(adjustedLocation, proposedLocation)) {
        break;
      }

      proposedLocation = adjustedLocation;
    }

    return proposedLocation;
  }

  arrangeLayout(force) {
    let confirmPromise =
      force || this.user.isTipHidden('confirmDiagramArrange') ?
        this.$q.when(false) :
        this.confirmModal({
          title: 'Auto-Arrange Process Steps?',
          body:
              'Auto-arrange attempts to optimize the layout by straightening connectors and ' +
              'uniformly spacing the process steps. You can make manual adjustments afterwards.  Continue?',
          okText: 'Auto-Arrange Steps',
          cancelText: 'Cancel',
          askToRemember: true,
        });

    confirmPromise.then((remember) => {
      if (remember) {
        this.user.setTipHidden('confirmDiagramArrange');
      }
      this.refreshingLayout = true;
      this.diagram.startTransaction('arrangeLayout');
      this.diagram.layout = this._go(go.LayeredDigraphLayout, {direction: 90});
      this.diagram.commitTransaction('arrangeLayout');

      this.$timeout(() => {
        this.diagram.startTransaction('resetLayout');
        this.diagram.layout = this._go(go.Layout);
        this.diagram.commitTransaction('resetLayout');

        this.persistCoordinates(this.diagram.nodes);
        this.refreshingLayout = false;
      }, 1000);
    });
  }

  centerDiagram() {
    this.diagram.startTransaction('centerDiagram');
    this.diagram.alignDocument(go.Spot.Top, go.Spot.Top);
    this.diagram.commitTransaction('centerDiagram');
  }

  closeTips() {
    this.clearQuickMenus();
    this.activeTip.visible = false;
    let onClose = this.activeTip.onClose;

    delete this.activeTip;
    let layer = this.diagram.findLayer('');

    this.diagram.model.startTransaction('startTip');

    _.each(_.concat(this.diagram.model.nodeDataArray, this.diagram.model.linkDataArray), (part) => {
      if (part.layer === 'tipLayer') {
        this.diagram.model.setDataProperty(part, 'layer', '');
      }
    });

    this.diagram.clearSelection();
    layer.opacity = 1;
    this.showingTip = false;
    if (_.isFunction(onClose)) {
      onClose();
    }

    this.diagram.model.commitTransaction('startTip');
  }

  /**
   * Show a diagram tip.
   * @param {any} parts The part or parts to select and keep opacity of 1.
   * @param {any} tip The tip object use to drive the pop-out text and remember tip state.
   * @param {any} location The location of the focused component
   * @param {function} onClose Custom logic to run after the tip is closed.
   * @returns {void}
   */
  showDiagramTip(parts, tip, location, onClose) {
    parts = _.isArray(parts) ? parts : [parts];
    let layer = this.diagram.findLayer('');

    this.diagram.model.startTransaction('startTip');

    this.activeTip = tip;
    this.activeTip.onClose = onClose;
    this.activeTip.left = location.x;
    this.activeTip.top = location.y;
    _.each(parts, (part) => {
      this.diagram.model.setDataProperty(part, 'layer', 'tipLayer');
    });

    this.activeTip.visible = true;

    layer.opacity = 0.4;
    //this.user.setTipHidden(tip.userTipProperty);
    this.showingTip = true;

    this.diagram.model.commitTransaction('startTip');
  }

  /**
   * goJs registered a relink of an existing link due to a user interaction. Update the step pointers accordingly.
   * @param {any} evt The link that was relinked
   * @returns {void}
   */
  onRelinkLink(evt) {
    let oldToStepId = _.get(this.diagram.toolManager.relinkingTool, 'originalToNode.data.key'),
      oldFromStepId = _.get(this.diagram.toolManager.relinkingTool, 'originalFromNode.data.key'),
      newToStepId = _.get(evt, 'subject.toNode.data.key'),
      newFromStepId = _.get(evt, 'subject.fromNode.data.key'),
      fromChanged = oldFromStepId !== newFromStepId,
      toChanged = oldToStepId !== newToStepId;

    if (!fromChanged && !toChanged) {
      return;
    }

    if (oldFromStepId && oldToStepId) {
      let link = _.find(this.processLinks, {from: oldFromStepId, to: oldToStepId}),
        $link = this.processLinks.$getRecord(link.$id);

      if (newToStepId && newFromStepId) {
        $link.to = newToStepId;
        $link.from = newFromStepId;
        this.processLinks.$save($link);
      } else {
        this.processLinks.$remove($link);
      }
    } else if (newToStepId && newFromStepId) {
      this.processLinks.$add({from: newFromStepId, to: newToStepId});
    }
  }

  /**
   * goJs registered a new link from a user interaction. Update the step pointers accordingly.
   * @param {any} evt The link added event
   * @returns {void}
   */
  onNewLink(evt) {

    let toStepId = _.get(evt, 'subject.toNode.data.key'),
      fromStepId = _.get(evt, 'subject.fromNode.data.key');

    if (toStepId && fromStepId) {
      this.processLinks.$add({from: fromStepId, to: toStepId});
    }
  }

  onLinkDeleted(evt) {
    for (let it = evt.subject.iterator; it.next();) {
      this.processLinks.$remove(
        _.find(this.processLinks, {from: _.get(it, 'value.data.from'), to: _.get(it, 'value.data.to')})
      );
    }
  }

  onNodeSelectionChanged(e) {
    if (_.get(e.data, 'key') === -1) {
      this.$timeout(() => e.isSelected = false);
      return;
    }

    let node = e.findObject('titleShape'),
      text = e.findObject('titleText');

    if (node !== null) {
      node.fill = e.isSelected ? this.colorPalette.SELECTED_FILL : this.colorPalette.NORMAL_FILL;
      node.stroke = e.isSelected ? this.colorPalette.SELECTED_STROKE : this.colorPalette.NORMAL_STROKE;

      this.selectedStep = e.isSelected ? e.data : null;
      if (!e.isSelected) {
        this.clearQuickMenus();
      }
    }

    if (text !== null) {
      text.stroke = e.isSelected ? '#333' : '#555';
    }
  }

  showQuickMenu(node) {
    this.$timeout(() => {
      this.$q.when(_.get(this, 'mouseUpDeferred.promise')).then(() => {
        const shape = node.findObject('titleShape');
        let bounds = node.actualBounds,
          shapeLocation = this.diagram.transformDocToView(shape.getDocumentPoint(go.Spot.TopCenter));

        this.quickX = shapeLocation.x - bounds.width / 2;
        this.quickXRight = shapeLocation.x + bounds.width / 2;
        this.quickY = shapeLocation.y;
        if (this.isQuickListOpen || this.isQuickMenuVisible) {
          this.isQuickMenuVisible = false;
        } else {
          this.isQuickMenuVisible = true;
        }
      });
    }, 100);
  }

  showQuickList(node, listObj) {
    this.$stateParams.hazardId = null;
    let location = this.diagram.transformDocToView(node.part.location);

    this.quickListX = location.x + node.actualBounds.width / 2 - 50;
    this.quickListY = location.y;
    this.isQuickMenuVisible = false;
    this.quickList = listObj;
    this.isQuickListOpen = false;
    this.$timeout(() => {
      this.isQuickListOpen = true;
    });
  }

  clearQuickMenus() {
    if (this.isQuickMenuVisible || this.isQuickListOpen) {
      this.$timeout(() => {
        this.isQuickMenuVisible = false;
        this.isQuickListOpen = false;
      });
    }
  }

  getSelectedNode() {
    for (let it = this.diagram.nodes; it.next();) {
      let node = it.value;

      if (node.isSelected) {
        return node;
      }
    }
  }

  /**
   * Define a function for creating a "port" that is normally transparent.
   * @param {string} name  Used as the GraphObject.portId
   * @param {object} spot Used to control how links connect and where the port is positioned on the node
   * @return {object} The port object
   */
  makePort(name, spot) {
    return this._go(go.Shape, 'Circle', {
      stroke: null,
      fill: 'transparent',
      desiredSize: new go.Size(7, 7),
      alignment: spot,
      alignmentFocus: spot,
      portId: name,
      fromLinkable: true,
      cursor: 'pointer',
    });
  }

  showSmallPorts(node, show) {
    if (this.isReadOnlyOrMobile) {
      return;
    }

    node.ports.each((port) => {
      if (port.portId !== '') {
        // don't change the default port, which is the big shape
        port.stroke = show ? this.colorPalette.SELECTED_STROKE : null;
      }
    });
  }

  /**
   * Delete a process step & related links from the data store.
   * @param {string} stepId the processStep.$id to be removed
   * @param {bool} skipConfirm Skip the confirmation modal.
   * @returns {Promise} The result of the delete operation
   */
  deleteStep(stepId, skipConfirm) {
    let step = _.find(this.processSteps, {$id: stepId});

    if (!step) {
      this.$log.error('Diagram error: deleting a step but stepId not found', {
        stepIdStr: stepId,
        processStepsCount: this.processSteps.length,
        processStepsKeys: angular.toJson(_.map(this.processSteps, (step) => step.$id)),
      });

      this.growl.error('Unable to delete step. Please try again or contact customer service.');

      return this.$q.resolve();
    }

    if (this.controlsMap[stepId]) {
      const control = _.first(this.controlsMap[stepId]);
      const msg =
        _.reduce(
          _.keys(control.hazards),
          (msgHtml, hazardId) => {
            msgHtml += `<li>${this.product.hazards[hazardId].name}</li>`;
            return msgHtml;
          },
          'The following hazards are controlled at this step. Remove the control measure at this step before deleting.' +
            '<div class="mt-3"><ul>'
        ) + '</ul></div>';

      return this.confirmModal({
        title: 'Cannot Delete Step',
        body: msg,
        okText: 'Remove Control Measure',
      })
        .then(() => this.controlsSvc.$removeControl(this.product.$id, control.$id))
        .then(() => {
          this.controlsMap[stepId].shift();
          this.refreshNode(stepId);
        });
    }
    let introducedHazard = _.find(this.$hazards, (h) => h.introductionStep === stepId);

    if (introducedHazard) {
      return this.confirmModal({
        title: 'Cannot Delete Step',
        body: `<b>${introducedHazard.name}</b> is introduced at this step. Adjust the hazard before deleting the step.`,
        okText: 'Edit Hazard',
      }).then(() => this.onEditHazard({hazard: introducedHazard}));
    }

    let confirmPromise = skipConfirm ? this.$q.resolve(true) : this.confirmDeleteModal(step.name);

    return confirmPromise
      .then(() => this.processSteps.$remove(step))
      .then(() => {
        let ingredients = _.keys(step.ingredients),
          cleanupPromises = [];

        cleanupPromises = _.concat(
          cleanupPromises,
          this.$q.all(
            _.map(this.product.ingredients, (ing, ingId) =>
              this.products.removeIngredientProcessStep(this.product.$id, ingId, stepId)
            )
          )
        );

        cleanupPromises = _.concat(
          cleanupPromises,
          this.$q.all(
            _.map(this.product.equipment, (equip, equipId) =>
              this.products.removeEquipmentProcessStep(this.product.$id, equipId, stepId)
            )
          )
        );

        // locate & remove ingredient pointers back to this process step.
        _.each(ingredients, (ingredientId) => this.ingredientService.removeStepPointers(ingredientId, step.$id));

        // remove all processLinks associated with the process step.
        _.each(
          _.filter(this.processLinks, (l) => l.from === stepId || l.to === stepId),
          (l) => cleanupPromises.push(this.processLinks.$remove(l))
        );

        return this.$q.all(cleanupPromises).catch((err) => {
          this.growl.error(
            'Error occurred when attempting to delete the process step. ' +
              'Please try again or contact support for assistance.'
          );
          this.$log.error(err);
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete process step.'));
  }

  deleteLink(linkPart, skipConfirm) {
    let confirmPromise = skipConfirm ? this.$q.when(true) : this.confirmDeleteModal('Link');

    confirmPromise
      .then(() => {
        if (linkPart.data.key < 0) {
          let link = _.find(this.diagram.model.linkDataArray, {from: linkPart.data.from, to: linkPart.data.to});

          this.diagram.model.removeLinkData(link);
        } else {
          this.processLinks
            .$remove(this.processLinks.$getRecord(linkPart.data.key))
            .catch((err) => this.$log.error('An error occurred deleting the graph objects.', err));
        }
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return;
        }

        this.$log.error(reason);
      });
  }

  editStepFromId(stepId) {
    this.editStep(this.$firebaseObject(this.processSteps.$ref().ref.child(stepId)));
  }

  controlHazard(stepId) {
    const step = this.processSteps.$getRecord(stepId);
    let introStepName = step.name;

    if (step.number) {
      introStepName = `${step.number}. ${introStepName}`;
    }
    let missingNumber = false;
    let hazardsList = _.map(_.sortBy(this.$hazards, ['name']), (h) => {
      const type = h.groupId ? this.product.hazardGroups[h.groupId].type : h.type;
      let introStep = this.processSteps.$getRecord(h.introductionStep) || {};
      let name = introStep.name || '';
      const stepNumber = parseInt(introStep.number);

      if (introStep.number) {
        name = `${introStep.number}. ${name}`;
      }

      missingNumber = missingNumber || _.isNaN(stepNumber);
      return _.assign(h, {
        name: h.groupId ? this.product.hazardGroups[h.groupId].name : h.name,
        type: type,
        typeName: _.get(this.hazardsSvc.hazardTypes[type], 'name', type),
        introducedAt: name,
        introducedAtStepNumber: !missingNumber && parseInt(introStep.number),
      });
    });

    hazardsList = _.orderBy(hazardsList, missingNumber ? 'introducedAt' : 'introducedAtStepNumber');

    return this.$uibModal
      .open({
        component: 'cfChooseFromListModal',
        size: 'lg',
        resolve: {
          chooseBtnHtml: () => '<i class="far fa-check fa-fw g-mr-5"></i>Control Hazards',
          itemName: () => 'hazard',
          multiple: true,
          instructionsHtml: () =>
            '<div class="alert alert-info">' +
            `Choose the hazards that will be controlled at step <b>"${introStepName}"</b>.</div>`,
          header: () => '<i class="far fa-exclamation-triangle fa-fw"></i> Choose Hazards to Control',
          itemsArray: () => hazardsList,
          columns: () => [
            {
              title: 'Type',
              property: 'typeName',
            },
            {
              title: 'Name',
              property: 'name',
            },
            {
              title: 'Introduced At Step',
              property: 'introducedAt',
            },
          ],
        },
      })
      .result.then((hazards) => {
        let firstHazard = _.first(hazards);
        let newControl = {hazards: {}, stepId};

        _.each(hazards, (h) => {
          newControl.hazards[h.$id] = true;
        });

        return this.controlsSvc.$pushControl(this.product.$id, newControl).then(($newControl) => {
          return this.onEditControl({hazardId: firstHazard.$id, stepId, controlId: $newControl.$id});
        });
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to create hazard control.'));
  }

  /**
   * Pop a modal for editing a process step.
   * @param {string} step the processStep to be edited. If omitted, a new process step will be created.
   * @returns {Promise} The result of the edit operation
   */
  editStep(step) {
    let $step = step || this.$firebaseObject(this.processSteps.$ref().ref.push());

    return this.$uibModal
      .open({
        component: 'cfProcessStep',
        backdrop: 'static',
        resolve: {
          $processStep: () => $step.$loaded(),
          disabled: () => this.isReadOnlyOrMobile,
        },
      })
      .result.then((editedStep) => {
        let chooseHazardsPromise = this.$q.resolve();

        if (!editedStep.checkedHazards) {
          editedStep.checkedHazards = true;
          chooseHazardsPromise = editedStep.$save().then(() => {
            return this.choiceModal({
              title: 'Step Hazards',
              buttonWidth: 228,
              body:
                '<div class="mb-3">Are any <i>new</i> hazards introduced or any <i>existing</i> hazards ' +
                `controlled at step <b>"${editedStep.name}"</b>?</div><small>Note: If you need to introduce ` +
                'AND control hazards, choose "Introduce New Hazard" for now and then ' +
                'afterward use the step menu to add your hazard controls.</small>',
              choice1Text: 'Introduce <i>New</i> Hazard(s)',
              choice2Text: 'Control <i>Existing</i> Hazard(s)',
            }).then((choice) => {
              if (choice === 1) {
                return this.onEditHazard({stepId: editedStep.$id});
              } else {
                return this.controlHazard(editedStep.$id);
              }
            });
          });
        }

        return chooseHazardsPromise.then(() =>
          this.growl.success('Process step <strong>' + editedStep.name + '</strong> saved successfully!')
        );
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return;
        }

        this.growl.error('Error ' + (step ? 'updating' : 'adding') + ' the process step.', {});
        this.$log.error(reason);
      });
  }

  scrollToStep(stepId) {
    let nodeObj = this.diagram.findNodeForKey(stepId);

    this.diagram.scrollToRect(new go.Rect(nodeObj.part.location.x, nodeObj.part.location.y, 1, 1));
  }

  printDiagram() {
    let newWindow = this.$window.open('', 'printWindow');

    if (!newWindow) {
      return;
    }

    let newDocument = newWindow.document,
      svg = this.diagram.makeSvg({
        document: newDocument,
        scale: 1,
      });

    newDocument.body.appendChild(svg);
    newWindow.print();
  }

  /**
   * Determine whether preventive controls at a given step are missing limits / procedures.
   * @param {object} step Step whose hazards are to be checked.
   * @return {Array}  Array of errors.
   */
  getHazardErrors(step) {
    const controlsAtStep = this.controlsMap[step.$id];
    let prefix = '';

    // Scan to make sure every control has a critical control (could be in another step)
    let errors = _.map(controlsAtStep, (curControl) => {
      let errString;

      if (!curControl.sops) {
        errString =
          prefix +
          _.capitalize(curControl.type) +
          ' control is missing one or more procedures for hazard "' +
          curControl.hazard +
          '"';
      }

      if (errString) {
        prefix = '\n';
        return errString;
      }
    });

    errors = _.compact(errors);

    return errors.length ? errors : null;
  }

  /**
   * Create a map of step Id's to the controls done at that step.
   * @param {Object} controls The product's controls array to map
   * @return {Object} A map of step Id's to controls.
   */
  createStepControlMap(controls) {
    let map = {};

    _.each(controls, (control) => {
      map[control.stepId] = map[control.stepId] || [];
      let firstHazardId = _.first(_.keys(control.hazards));

      map[control.stepId].push(
        _.assign({hazard: firstHazardId ? _.get(this.product, `hazards[${firstHazardId}].name`) : 'Not found'}, control)
      );
    });

    return map;
  }

  /**
   * Persist the node locations to the Firebase step.
   * @param {object} nodes A collection of nodes that uses a goJs iterator property.
   * @returns {void}
   */
  persistCoordinates(nodes) {
    for (let it = nodes.iterator; it.next();) {
      if (it.value.name !== 'Node') {
        return;
      }

      let node = it.value,
        location = go.Point.stringify(node.part.position),
        step = this.processSteps.$getRecord(node.data.key);

      if (step.loc !== location) {
        step.loc = location;
        this.processSteps.$save(step);
      }
    }
  }

  zoomIn() {
    this.diagram.commandHandler.increaseZoom();
    this.clearQuickMenus();
  }

  zoomOut() {
    this.diagram.commandHandler.decreaseZoom();
    this.clearQuickMenus();
  }

  getRadius() {
    if (!this.diagram) {
      return 42;
    }

    if (this.diagram.scale < 0.8) {
      return 48;
    } else if (this.diagram.scale > 1.2) {
      return 49;
    } else {
      return 48;
    }
  }

  toggleLegend() {
    this.showLegend = !this.showLegend;
  }

  deleteSelected() {
    let multiple = this.diagram.selection.count > 1;
    let confirmPromise = multiple ? this.confirmDeleteModal('chart items') : this.$q.when(true);

    confirmPromise
      .then(() => {
        for (let it = this.diagram.selection.iterator; it.next();) {
          let curPart = it.value;

          if (curPart.type.name === 'Link') {
            this.deleteLink(curPart, multiple);
          } else {
            this.deleteStep(curPart.data.key, multiple);
          }
        }
      })
      .catch((err) => {
        this.$log.error('Error deleting selection.', err);
        this.growl.error(
          'An error occurred deleting the chart items. If the issue persists, ' + 'please contact customer support.'
        );
      });
  }

  /**
   * Save the scroll location, quick button menu state, whether to animate, etc.
   * @returns {void}
   */
  saveState() {
    if (this.diagram) {
      this.diagramState.position = this.diagram.position;
      let selectedNode = this.getSelectedNode();

      if (selectedNode) {
        this.diagramState.selectedNode = selectedNode.data.key;
      }
    }
  }

  /**
   * Upon returning from a sub-view, restore the scroll location etc.
   * @returns {void}
   */
  restoreState() {
    this.diagram.animationManager.isEnabled = this.diagramState.animateLayout;
    this.diagramState.animateLayout = false;
    this.$timeout(() => {
      if (this.diagramState.position) {
        this.diagram.position = this.diagramState.position;
      }
      if (this.diagramState.selectedNode) {
        let selectedNode = this.diagram.findNodeForKey(this.diagramState.selectedNode);

        if (selectedNode) {
          selectedNode.isSelected = true;
          this.$timeout(() => {
            selectedNode.isSelected = true;
          }, 200); // For some reason isSelected is set back to false if we run this too soon.
        }
      }
    });
  }

  saveAsTemplate() {
    this.$uibModal
      .open({
        component: 'cfSavePlanAsTemplateModal',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
          product: () => this.product,
          planTemplates: () => this.products.getPlanTemplates(this.product.organizationId),
          defaultTemplate: () => this.product.savedToTemplate,
        },
      })
      .result.then((template) => {
        this.$log.log(template);
        this.product.savedToTemplate = template.$id;
        return this.product.$save();
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return;
        }

        this.$log.error('Error while saving process flow as template.', this.$log.toString(reason));
        this.growl.error('Could not save process flow as a template.');
      });
  }

  loadTemplate() {
    const onPayAsGo = this.user.onPayAsGoPlan() && !this.user.isCfAdmin();
    let getTemplatePromise;

    // If the plan is unlocked and already loaded a template, don't allow them to load a different template. Otherwise
    // they could game the system and reuse a single unlocked plan record for multiple plans.
    if (
      onPayAsGo &&
      this.product.fromTemplate &&
      this.product.fromTemplateOrgId &&
      (this.product.chargeId || this.product.unlockedBy)
    ) {
      getTemplatePromise = this.products
        .getPlanTemplate(this.product.fromTemplateOrgId, this.product.fromTemplate)
        .then((template) =>
          this.confirmModal({
            title: 'Reload Plan Template?',
            body:
              `Reload the plan template: <b>${template.name}</b>? ` +
              'This will remove all existing process steps, hazards, etc.',
            okText: 'Reload Template',
          }).then(() =>
            _.assign(template, {
              organizationId: this.product.fromTemplateOrgId,
              options: {hazards: true, ingredients: true, equipment: true, planAnalysis: true},
            })
          )
        );
    } else {
      this.loadingTemplate = true;
      getTemplatePromise = this.$q
        .all({
          orgTemplates: this.user.onPrimaryOrg() ?
            this.products.getPlanTemplates(this.user.organizationId) :
            this.$q
              .all({
                primaryOrgTemplates: this.products.getPlanTemplates(this.user.organizationId),
                orgTemplates: this.products.getPlanTemplates(this.user.orgContext.id),
              })
              .then(({primaryOrgTemplates, orgTemplates}) => _.assign(primaryOrgTemplates, orgTemplates)),
          cfTemplates: this.products.getPlanTemplates(this.SAMPLE_ORGANIZATION_ID),
        })
        .then(({orgTemplates, cfTemplates}) => {
          this.loadingTemplate = false;
          return this.$uibModal.open({
            component: 'cfSelectPlanTemplateModal',
            backdrop: 'static',
            resolve: {
              user: () => this.user,
              showWarning: () => !!this.processSteps.length,
              userInitiated: () => true,
              recommendedTemplates: () =>
                this.product.category ? this.products.getTemplatesFromCategory(this.product.category) : null,
              orgTemplates: () => orgTemplates,
              cfTemplates: () => cfTemplates,
            },
          }).result;
        });
    }

    getTemplatePromise
      .then((template) => {
        this.loadingTemplate = true;
        return this.products.loadTemplate(this.product, template.organizationId, template.$id, {
          hazards: template.options.hazards,
          ingredients: template.options.ingredients,
          equipment: template.options.equipment,
          planAnalysis: template.options.planAnalysis,
        });
      })
      .then(() => this.sopService.getProductSops(this.product.organizationId, this.product.$id))
      .then((sopsArray) => {
        this.growl.success('Plan Template Loaded!');
        this.sops = _.keyBy(sopsArray, '$id');

        _.each(this.processSteps, (step) => {
          this.diagram.model.startTransaction('addStep');
          this.diagram.model.addNodeData(this.hydrateNodeData(step));
          this.diagram.model.commitTransaction('addStep');
        });

        this.diagram.centerRect(this.diagram.documentBounds);
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return;
        }

        this.$log.error('Error while loading process flow template.', this.$log.toString(reason));
        this.growl.error('Could not load template.');
      })
      .finally(() => this.loadingTemplate = false);
  }

  defineGoRectangles() {
    go.Shape.defineFigureGenerator('RoundedTopRectangle', (shape, w, h) => {
      // this figure takes one parameter, the size of the corner
      let p1 = 5; // default corner size

      if (shape !== null) {
        const param1 = shape.parameter1;

        if (!isNaN(param1) && param1 >= 0) {
          p1 = param1;
        } // can't be negative or NaN
      }

      p1 = Math.min(p1, w / 2);
      p1 = Math.min(p1, h / 2); // limit by whole height or by half height?

      let geo = new go.Geometry();

      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(
        new go.PathFigure(0, p1)
          .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
          .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
          .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
          .add(new go.PathSegment(go.PathSegment.Line, w, h))
          .add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
      );

      // don't intersect with two top corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
      geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);

      return geo;
    });

    go.Shape.defineFigureGenerator('RoundedBottomRectangle', (shape, w, h) => {
      // this figure takes one parameter, the size of the corner
      let p1 = 5; // default corner size

      if (shape !== null) {
        let param1 = shape.parameter1;

        if (!isNaN(param1) && param1 >= 0) {
          p1 = param1; // can't be negative or NaN
        }
      }
      p1 = Math.min(p1, w / 2);
      p1 = Math.min(p1, h / 2); // limit by whole height or by half height?

      let geo = new go.Geometry();

      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(
        new go.PathFigure(0, 0)
          .add(new go.PathSegment(go.PathSegment.Line, w, 0))
          .add(new go.PathSegment(go.PathSegment.Line, w, h - p1))
          .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - p1, h - p1, p1, p1))
          .add(new go.PathSegment(go.PathSegment.Line, p1, h))
          .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, h - p1, p1, p1).close())
      );

      // don't intersect with two bottom corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
      geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);

      return geo;
    });
  }

  highlightShape(obj, shapeName, color) {
    let shapeObj = obj.findObject(shapeName);

    if (!shapeObj) {
      return;
    }
    shapeObj.fill = color;
  }

  highlightHazard(hazardId) {
    // unset all first
    let introducedAtStep = this.highlightHazardNodes(hazardId);
    let controlSteps = this.highlightControlNodes(hazardId);

    this.highlightHazardControlPaths(introducedAtStep, controlSteps || [null]);
  }

  clearHighlights(commit, clearValue) {
    if (commit) {
      this.diagram.model.startTransaction('updateNode');
    }
    _.each(this.diagram.model.linkDataArray, (link) =>
      this.diagram.model.setDataProperty(link, 'linkHighlighted', clearValue)
    );
    _.each(this.diagram.model.nodeDataArray, (node) => {
      this.diagram.model.setDataProperty(node, 'highlightControl', clearValue);
      this.diagram.model.setDataProperty(node, 'highlightHazard', clearValue);
      this.diagram.model.setDataProperty(node, 'highlightStep', clearValue);
    });
    if (commit) {
      this.pauseViewportListener = true;
      this.diagram.model.commitTransaction('updateNode');
      this.$timeout(() => {
        this.pauseViewportListener = false;
      }, 200);
    }
  }

  highlightHazardNodes(hazardId) {
    let hazard = hazardId && this.product.hazards && this.product.hazards[hazardId];
    let introducedStep = hazard && _.find(this.processSteps, (step) => hazard.introductionStep === step.$id);
    let nodeData = introducedStep && this.diagram.model.findNodeDataForKey(introducedStep.$id);

    if (nodeData) {
      this.diagram.model.setDataProperty(nodeData, 'highlightHazard', true);
    }

    return introducedStep ? introducedStep.$id : null;
  }

  highlightControlNodes(hazardId) {
    let controlSteps = _.map(
      _.filter(this.product.controls, (control) => control.hazards && control.hazards[hazardId]),
      (c) => c.stepId
    );

    _.each(controlSteps, (stepId) => {
      let nodeData = this.diagram.model.findNodeDataForKey(stepId);

      this.diagram.model.setDataProperty(nodeData, 'highlightControl', true);
    });

    return controlSteps;
  }

  highlightHazardControlPaths(introducedStep, controlSteps) {
    let inPlaySteps = {};
    let inPlayLinks = {};

    if (introducedStep) {
      _.each(controlSteps, (controlStep) => {
        let q = [];

        q.push({
          steps: [introducedStep],
          links: [],
        }); // enqueue
        while (q.length) {
          let currentPathObj = q.shift(); // dequeue
          let currentStep = _.last(currentPathObj.steps);

          // If we found the destination, add the steps in the path to the 'inPlaySteps'
          if (currentStep === controlStep) {
            _.each(currentPathObj.steps, (step) => {
              inPlaySteps[step] = true;
            });
            _.each(currentPathObj.links, (link) => {
              inPlayLinks[link.$id] = true;
            });
            continue;
          }
          let links = _.filter(
            this.processLinks,
            (link) => link.from === currentStep && _.indexOf(currentPathObj.steps, link.to) === -1
          );

          if (!links.length) {
            continue;
          }
          _.each(links, (link) => {
            q.push({
              steps: _.concat(currentPathObj.steps, link.to),
              links: _.concat(currentPathObj.links, link),
            }); // enqueue
          });
        }
      });
    }

    _.each(inPlaySteps, (val, stepId) => {
      let nodeData = this.diagram.model.findNodeDataForKey(stepId);

      this.diagram.model.setDataProperty(nodeData, 'highlightStep', true);
    });
    _.each(inPlayLinks, (val, linkId) => {
      let linkData = this.diagram.model.findLinkDataForKey(linkId);

      this.diagram.model.setDataProperty(linkData, 'linkHighlighted', true);
    });
  }
}

module.exports = Controller;
