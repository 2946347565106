class Controller {
  constructor($state, $log, $timeout, $q, products, confirmModal, confirmDeleteModal,
              growl, preventiveControlTypes, preventDirtyNav, utils, organizations, hazardsService,
              constantsService, $scope, cfpLoadingBar, $uibModal, allergens, controlsService, haccpControlTypes) {
    'ngInject';

    this.$state = $state;
    this.$q = $q;
    this.$log = $log;
    this.confirmDeleteModal = confirmDeleteModal;
    this.growl = growl;
    this.$timeout = $timeout;
    this.preventDirtyNav = preventDirtyNav;
    this.preventiveControlTypes = preventiveControlTypes;
    this.haccpControlTypes = haccpControlTypes;
    this.productsSvc = products;
    this.confirmModal = confirmModal;
    this.utils = utils;
    this.organizations = organizations;
    this.constantsService = constantsService;
    this.$scope = $scope;
    this.cfpLoadingBar = cfpLoadingBar;
    this.$uibModal = $uibModal;
    this.allergens = allergens;
    this.hazardsSvc = hazardsService;
    this.controlsSvc = controlsService;

    this.$prevHazard = null;
    this.hazardTypes = _.map(this.hazardsSvc.hazardTypes, (typeObj, typeId) =>
        ({name: typeObj.name, value: typeId}));
  }

  $onInit() {
    this.preventNavBody = 'You have unsaved changes to the ' + this.product.brandName + ' hazard.';
    this.$timeout(() => {
      this.preventDirtyNav(this.hazardForm, this.preventNavBody, () => {
        this.save(true);
      });
    }, 0);

    this.isHaccp = this.product.planType === 'haccpPlan';
    this.processSteps = this.product.processSteps;
    if (this.$hazard.groupId) {
      this.group = this.product.hazardGroups[this.$hazard.groupId];
      this.groupHazards = this.group.hazards.split('|');
    }

    this.controlTypes = this.isHaccp ? this.haccpControlTypes : this.preventiveControlTypes;
  };

  hydrateControls() {
    const allControls =  _.cloneDeep(this.product.controls || {});

    this.controls = _.reduce(allControls, (controlObj, control, controlId) => {
      if (_.some(control.hazards, (val, hazardId) => hazardId === this.$hazard.$id)) {
        controlObj[controlId] = control;
      }
      return controlObj;
    }, {});

    _.each(this.controls, control => {
      control.sops = _.mapValues(control.sops, (sop, key) => this.planSops[key]);
    });
  }

  $doCheck() {
    this.isDirty = _.get(this, 'hazardForm.$dirty', false);

    if (this.$hazard !== this.$prevHazard) {
      this.hydrateControls();
      this.$prevHazard = this.$hazard;
    }
  }

  isEmpty(object) {
    return _.isEmpty(object);
  }

  save(close) {
    if (this.hazardForm.$invalid) {
      return this.confirmModal({
        title: 'Hazard Incomplete',
        hideCancelButton: true,
        body: 'Please enter all required information before saving.'
      })
      .then(() => this.utils.setFormFieldsToTouched(this.hazardForm));
    }

    if (this.hazardForm.$pristine) {
      return close ?
        this.onSaveAndClose({hazard: _.values(this.$hazard)}) :
        this.onSave({hazard: _.values(this.$hazard)});
    }

    this.$hazard.updatedOn = firebase.database.ServerValue.TIMESTAMP;
    this.$hazard.updatedBy = this.user.uid;

    let newStepPromise = this.$q.resolve();

    if (this.newStepName) {
      newStepPromise = this.productsSvc.pushStep(this.product, {name: this.newStepName}).then(newStepRef => {
        this.$hazard.introductionStep = newStepRef.key;
      });
    }

    return newStepPromise.then(() => this.$hazard.$save())
      .then(() => {
        if (!close && _.isFunction(this.onSave)) {
          this.onSave({hazard: _.values(this.$hazard)});
        }

        if (close && _.isFunction(this.onSaveAndClose)) {
          this.onSaveAndClose({hazard: _.values(this.$hazard)});
        }
      })
      .then(() => {
        if (this.hazardForm) { this.hazardForm.$setPristine(); }

        this.organizations.setMilestoneAchieved(this.product.organizationId,
          this.organizations.milestones.HAZARDS_STARTED);
      })
      .catch(err => {
        this.$log.error(err);
        this.growl.error('An error occurred saving the hazard properly. Please try again or contact customer support.');
      });
  }

  cancel() {
    if (this.hazardForm.$dirty) {
      this.confirmModal({
        title: 'Unsaved Changes!',
        okText: 'Yes, I\'m sure.',
        cancelText: 'No, stay here.',
        body: 'It looks like you have unsaved changes. Are you sure you want to undo ' +
          'your updates and return to the diagram?'
      })
        .then(() => this.onCancel());
    } else {
      this.onCancel();
    }
  }

  getMarkup(objs, property, noneText) {
    if (!_.keys(objs).length) { return '<p class="text-muted text-center">' + noneText + '</p>'; }

    let markup = '<ol>';

    _.each(objs, (obj) => {
      if (obj) {
        markup += '<li>' + _.truncate(obj[property], {length: 35}) + '</li>';
      }
    });

    markup += '</ol>';

    return markup;
  }

  getSopBadge(controlId, control) {
    if (_.isEmpty(_.keys(control.sops))) {
      return 'g-bg-orange';
    }

    return 'g-bg-primary';
  }

  editControl(hazardId, controlId) {
    this.getHazardWipPromise(
      'Please save your work on the hazard before adding a control.',
      'Please enter required hazard information and save your work before adding a control'
    )
    .then(() => this.onEditControl({hazardId, controlId}))
    .finally(() => this.utils.setFormFieldsToTouched(this.hazardForm));
  }

  addExistingControl(hazard) {
    this.getHazardWipPromise(
      'Please save your work on the hazard before adding a control.',
      'Please enter required hazard information and save your work before adding a control'
    )
    .then(() => this.onAddExistingControl({hazard}))
    .then((control) =>
        this.$timeout(() => {
          this.controls[control.$id] = control;
        })
    )
    .finally(() => this.utils.setFormFieldsToTouched(this.hazardForm));
  }

  $removeControl(hazard, controlId) {
    const control = this.product.controls[controlId];

    this.confirmDeleteModal('Control', {
      headerHtml: '<span class="text-danger">Remove <strong>Control</strong>?</span>',
      confirmText: 'Remove',
      body: `Are you sure you want to remove the <strong>${control.type}</strong> control for hazard  ` +
          `<strong>${this.$hazard.name}</strong>?`
    }).then(() => {
      delete control.hazards[hazard.$id];
      return this.controlsSvc.removeHazardFromControl(this.product.$id, hazard.$id, controlId)
          .then((wasControlDeleted) => {
            if (wasControlDeleted) {
              _.each(control.sops, (sop, sopId) => delete this.planSops[sopId]);
            }
            delete this.controls[controlId];
          });
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to remove control from hazard.'));
  }

  getHazardWipPromise(dirtyMsg, invalidMsg) {
    let wipPromise = this.$q.resolve();

    if (this.hazardForm.$dirty && !this.hazardForm.$invalid) {
      wipPromise = this.confirmModal({
        title: '<span class="far fa-exclamation-triangle fa-fw"></span> Unsaved Changes',
        okText: 'Save Hazard & Continue',
        cancelText: 'Cancel',
        body: dirtyMsg
      })
        .then(() => this.save())
        .then(() => this.hazardForm.$setPristine());
    } else if (this.hazardForm.$invalid) {
      return this.confirmModal({
        title: '<span class="far fa-exclamation-triangle fa-fw"></span> Hazard Incomplete',
        okText: 'Ok',
        hideCancelButton: true,
        body: invalidMsg
      }).then(() => this.$q.reject());
    }

    return wipPromise;
  }

  getControlType(controlTypeId) {
    return _.find(this.controlTypes, {id: controlTypeId});
  }
}

module.exports = Controller;
