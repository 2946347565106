class Controller {
  constructor(
    $rootScope,
    $scope,
    $log,
    $state,
    authentication,
    menus,
    productSearch,
    notifications,
    growl,
    $uibModal,
    $http,
    $window,
    utils,
    deviceDetector,
    $q,
    authorization,
    billing,
    orgPerspectives,
    messageServices,
    users,
    products,
    $timeout,
    subscriptionService,
    moment,
    confirmModal,
    services,
    ENV,
    cfpLoadingBar,
    organizations,
    $document,
    selectModal,
    ElasticSearchAdmin,
    drift,
    V2_URL,
    LOCAL_DEVELOPMENT
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.$state = $state;
    this.authentication = authentication;
    this.menus = menus;
    this.productSearch = productSearch;
    this.notifications = notifications;
    this.growl = growl;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$window = $window;
    this.utils = utils;
    this.deviceDetector = deviceDetector;
    this.$q = $q;
    this.authorization = authorization;
    this.billing = billing;
    this.orgPerspectives = orgPerspectives;
    this.messageServices = messageServices;
    this.users = users;
    this.products = products;
    this.$timeout = $timeout;
    this.subscriptionService = subscriptionService;
    this.moment = moment;
    this.confirmModal = confirmModal;
    this.services = services;
    this.ENV = ENV;
    this.V2_URL = V2_URL;
    this.LOCAL_DEVELOPMENT = LOCAL_DEVELOPMENT;
    this.cfpLoadingBar = cfpLoadingBar;
    this.organizations = organizations;
    this.$document = $document;
    this.selectModal = selectModal;
    this.ElasticSearchAdmin = ElasticSearchAdmin;
    this.drift = drift;

    this.getEsIndexOptions();
    this.subMenuOpen = {};
    this.cancelCloseSubMenuMap = {};
  }

  $onInit() {
    this.searchDisabled = true;
    this.searchOpen = false;
    this.isNavCollapsed = true;
    delete this.user;
    delete this.switchableOrgs;
    this.isMobile = this.deviceDetector.isMobile();
    this.isIe = !!this.$document.documentMode;
    this.hideIeMsg = false;
    this.isDev = this.ENV === 'development';

    this.initMenu(this.$state.current);

    this.$scope.$watch('vm.user.subscription', (subscription, oldSubscription) => {
      if (
        subscription && !oldSubscription ||
        subscription && oldSubscription && subscription.id !== oldSubscription.id
      ) {
        if (!subscription) {
          return;
        }
        const isDemoAccount = _.get(subscription, 'metadata.isDemoAccount', '').toLowerCase() === 'true';

        this.planText = _.get(subscription, 'plan.name') ?
          _.get(subscription, 'plan.name', '')
            .replace(/(FoodReady)/, '')
            .trim() :
          '';

        if (isDemoAccount) {
          return;
        }
        this.checkSubscriptionStatus(subscription).catch((reason) => {
          if (this.utils.isModalDismissalByUser(reason)) {
            this.$window.location.reload();
            return;
          }
          this.$log.error('Unable to determine user subscription status.', this.$log.toString(reason));
        });
      }
    });

    this.$scope.$watch('vm.user', () => {
      if (!this.user) {
        delete this.switchableOrgs;
        delete this.isMainOrg;
        delete this.invoices;
        this.userInitialized = undefined;
        if (_.isFunction(_.get(this, 'shoppingCartItems.$destroy'))) {
          this.shoppingCartItems.$destroy();
        }

        return;
      }

      if (this.userInitialized /*|| vm.user.getPerspective() === orgPerspectives.PENDING_SIGNUP*/) {
        return;
      }

      this.userInitialized = true;
      this.isMainOrg = this.user.orgContext.id === this.user.organizationId;
      this.isPartner = this.user.isPartner();

      if (this.user.isReviewsUser()) {
        //vm.productSearch = productSearch.getSearch(vm.user, 5);

        //this.setupMessageNotifier(); // messages obsolete?
        this.setupNotificationsNotifier();
        this.getSwitchableOrgs().then((orgs) => this.switchableOrgs = orgs);

        this.users.$getShoppingCartItems(this.user.uid).then((result) => this.shoppingCartItems = result);

        this.services
          .getOrgsServices(this.user.orgContext.id)
          .then((invoices) => this.invoices = invoices)
          .catch((err) => this.$log.error('Error getting org services', this.$log.toString(err)));

        /*
                this.organizations.messageOrgTopicCount(this.user.orgContext.id)
                  .then((count) => this.showMessagesIcon = count > 0);
        */
      }
      this.checkSubscriptionId().catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          this.$window.location.reload();
          return;
        }
        this.$log.error('Unable to determine user subscription status.', this.$log.toString(reason));
      });
    });

    this.unregisterFn = this.$rootScope.$on('$stateChangeSuccess', (event, toState) => {
      this.initMenu(toState);
      this.isNavCollapsed = true;

      _.each(_.get(this, 'menu.items', []), (item) => {
        if (
          this.user &&
          this.$state.includes('products.edit') &&
          _.startsWith(item.state, 'marketplace') &&
          !this.user.isCfAdmin()
        ) {
          this.$q
            .all([
              this.products.getOrganizationId(this.$state.params.productId),
              this.products.isSampleProduct(this.$state.params.productId),
            ])
            .then(
              ([orgId, isSampleProduct]) => item.isActive = this.user.orgContext.id !== orgId && !isSampleProduct
            );
        } else {
          let stateParts = item.state.split('.'),
            toCheck = stateParts[0] === 'analysis' ? stateParts[0] + '.' + stateParts[1] : stateParts[0];

          item.isActive = this.$state.includes(toCheck);
        }
      });
    });
  }

  $onDestroy() {
    this.unregisterFn();
  }

  initMenu(state) {
    this.menu = state.data && state.data.menuId ? this.menus.getMenu(state.data.menuId) : this.menu;
  }

  checkChangeSubscription() {
    return this.$q
      .when(
        this.trialDaysLeft ?
          this.confirmModal({
            title: 'Change Subscription Plan?',
            body:
                'Changing your subscription plan while on a free trial will activate ' +
                'the plan and cancel the trial. Are you sure?',
            okText: 'Yes, Change Subscription',
            cancelText: 'Cancel',
          }) :
          true
      )
      .then(() => this.changeSubscription());
  }

  checkSubscriptionStatus(subscription) {
    this.isSubscriptionOwner = subscription.customer === this.user.customerId;
    const subscriptionStatus = subscription.status;
    const current_period_start = subscription.current_period_start;

    if (subscriptionStatus === this.billing.subscriptionStatuses.TRIALING) {
      this.trialDaysLeft = this.moment(subscription.trial_end * 1000).diff(this.moment(), 'days');
    }

    if (
      subscriptionStatus === this.billing.subscriptionStatuses.ACTIVE ||
      subscriptionStatus === this.billing.subscriptionStatuses.TRIALING ||
      this.user.isCfAdmin()
    ) {
      return this.$q.when(true);
    }

    if (!this.isSubscriptionOwner) {
      this.$log.info(`Subscription state ${subscriptionStatus} but cannot be resolved by 
        user because they are not owner.`);
      this.confirmModal({
        title: 'Subscription Problem',
        body:
          'There is a problem with your organization\'s subscription. Only the account owner can resolve it. ' +
          'Please contact your organization\'s administrator or FoodReady support for help.',
        okText: 'Ok',
        hideCancelButton: true,
      }).finally(() => this.$q.reject('cancel'));
    }

    if (subscriptionStatus === this.billing.subscriptionStatuses.MISSING) {
      return this.confirmModal({
        title: 'Subscription Problem',
        body: 'There is a problem with your FoodReady subscription. Please choose a plan to continue.',
        okText: 'Continue',
        cancelText: 'Exit',
      })
        .then(() => this.changeSubscription())
        .then((result) => {
          if (result === 'modalDismissed') {
            return this.$q.reject('cancel');
          }
          this.$log.info('User with missing subscription just created a new one.', null, {notify: true});
        });
    }
    if (
      (subscriptionStatus === this.billing.subscriptionStatuses.PAST_DUE ||
      subscriptionStatus === this.billing.subscriptionStatuses.UNPAID) &&
      Date.now() > (current_period_start + 1296000) * 1000 // current start period + 15 days
    ) {
      let activating = !this.user.customerRec.sources.data.length;

      this.$log.info(activating ? 'User logged in...trial period ended.' : 'User logged in...payment past due.', null, {
        notify: true,
      });

      if (activating) {
        return this.$uibModal
          .open({
            component: 'frPricingPage',
            size: 'xl',
            backdrop: 'static',
          })
          .result.then((result) => {
            if (result === 'modalDismissed') {
              this.$window.location.reload();
              return this.$q.reject('cancel');
            }
          });
      } else {
        let title = 'Payment Past Due';
        let msg =
          'Your previous subscription payment is past due.<br>Please enter a new payment source to make a payment. <br>' +
          'If you have any questions, feel free to <a href="" ng-click="vm.drift.openSidebar()">Chat With Us</a>';

        return this.confirmModal({
          title: title,
          body: msg,
          okText: 'Continue',
          cancelText: 'Exit',
        })
          .then(() => activating ? this.changeSubscription() : this.changeDefaultSource())
          .then((result) => {
            if (result === 'modalDismissed') {
              return this.$q.reject('cancel');
            }
          });
      }
    } else if (subscriptionStatus === this.billing.subscriptionStatuses.CANCELED) {
      this.$log.info(`Subscription state ${subscriptionStatus}. Owner to be asked to resolve.`);

      return this.$uibModal
        .open({
          component: 'frPricingPage',
          size: 'xl',
          backdrop: 'static',
        })
        .result.then((result) => {
          if (result === 'modalDismissed') {
            return this.$q.reject('cancel');
          }
        });
    } else {
      return this.$q.when(true);
    }
  }

  getSwitchableOrgs() {
    if (this.user.isCfAdmin()) {
      return this.$q.when(null);
    }
    return this.$q
      .all(_.mapValues(this.user.organizations(), (val, orgId) => this.authorization.getClaims(orgId, val.role)))
      .then((orgs) => {
        let eligibleOrgs = _.pickBy(orgs, (rec) => rec.claims[this.authorization.claims.SWITCH_TO] === true);

        return _.map(eligibleOrgs, (org, orgId) => orgId);
      });
  }

  /**
   * Initialize the list of unread messages. Also register a handler when the user's remoteWatcher detects a change
   * in the collection '/messageTopics'. When a change occurs, add the topic to the unreadMessages list if the user
   * has not read the message. Or, if the user has read the message, remove it from the list of unreadMessages.
   * @return {void}
   */
  setupMessageNotifier() {
    this.unreadMessages = [];
    this.lastMessageHtml = {};

    // Initialize the list of unread messages
    this.messageServices
      .getUnreadMessageTopics()
      .then((messageTopics) => {
        this.unreadMessages = messageTopics;
        _.each(this.unreadMessages, (topic) => {
          if (!topic.messages) {
            this.$log.error('Unread topic with no messages. ', {topicId: topic.$id});
            this.lastMessageHtml[topic.$id] = '';
            return;
          }

          this.messageServices.lastMessageSummaryHtml(topic.messages).then((html) => {
            this.lastMessageHtml[topic.$id] = html;
          });
        });
      })
      .catch((err) => {
        this.$log.error('Error getting unread messages', this.$log.toString(err));
      });

    // Register a handler to update the UI when a messageTopic changes.
    this.user.onRemoteWatchReceived('messageTopics', (topicId) => {
      this.messageServices
        .getTopic(topicId)
        .then((topic) => {
          topic.participatingUsers = topic.participatingUsers || {};
          topic.participatingOrgs = topic.participatingOrgs || {};

          // If the last message sent on this topic was not read by the logged in user, add to the unreadMessages array.
          let lastMessage = topic.lastMessageSent || topic.prevLastMessageSent;
          let myLastMessageRead =
            topic.participatingUsers[this.user.uid] || topic.participatingOrgs[this.user.orgContext.id];

          // If the user has NOT seen the most recent message, add it to the list.
          if (myLastMessageRead && (myLastMessageRead === true || myLastMessageRead < lastMessage)) {
            // If the topic is already on the list, just return.
            if (_.findIndex(this.unreadMessages, (msg) => msg.$id === topicId) !== -1) {
              return;
            }

            topic.$id = topicId;

            return this.messageServices.lastMessageSummaryHtml(_.values(topic.messages)).then((html) => {
              if (!html) {
                return;
              }

              this.lastMessageHtml[topic.$id] = html;
              this.unreadMessages.push(topic);
            });
          } else {
            _.remove(this.unreadMessages, (rec) => rec.$id === topicId);
          }
        })
        .catch((err) => {
          this.$log.error('Error getting message topic', this.$log.toString(err));
        });
    });
  }

  /**
   * Initialize the list of unread notifications. Also register a handler when the user's remoteWatcher detects a change
   * in the collection '/notifications'. When a change occurs, add the topic to the unreadNotifications list if the
   * user has not seen the notification. Or, if the user has seen the notification, remove it from the list of
   * unreadNotifications.
   * @return {void}
   */
  setupNotificationsNotifier() {
    this.unreadNotifications = [];

    // Register a handler to update the UI when a notifications changes.
    this.user.onRemoteWatchReceived('notifications', (notificationId) => {
      let currentIndex = _.findIndex(this.unreadNotifications, (n) => n.$id === notificationId);

      this.notifications.get(notificationId).then((notification) => {
        if (_.find(notification.read, (uid) => this.user.uid === uid)) {
          if (currentIndex !== -1) {
            _.remove(this.unreadNotifications, (notification) => notification.$id === notificationId);
          }
        } else {
          if (currentIndex === -1) {
            this.unreadNotifications.push(_.assign(notification, {$id: notificationId}));
          }
        }

        this.$timeout(() => {
          this.notificationCount = this.unreadNotifications.length;
        });
      });
    });
  }

  checkBillingAccess() {
    return this.user && this.user.hasPermission(this.authorization.claims.BILLING_READ);
  }

  checkUsersAccess() {
    return this.user && this.user.hasPermission(this.authorization.claims.USERS_READ);
  }

  logout() {
    this.authentication.logout();
  }

  toggleCollapsibleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  pendingSignup() {
    return this.user && _.get(this.user, 'orgContext.perspective') === this.orgPerspectives.PENDING_SIGNUP;
  }

  hideMenu() {
    return this.$state.includes('onboarding') || this.$state.includes('filePortal');
  }

  search(searchText) {
    if (!this.productSearch) {
      this.productSearch = this.productSearch.getSearch(this.user, 5);
    }

    let body = this.productSearch.getSearchQuery();

    _.remove(body.query.bool.must, (e) => e.match_phrase_prefix);

    if (searchText) {
      body.query.bool.must.push({
        /* eslint-disable camelcase */
        match_phrase_prefix: {
          brandName: {
            query: searchText.toLowerCase(),
            slop: 3, // Allow terms to appear out of order by this many positions
            max_expansions: 20, // To improve performance, limit results of the final search term to this many
          },
        },
      });
    } else {
      return null;
    }

    this.productSearch.setSearchQuery(body);

    return this.productSearch
      .search()
      .catch((err) => {
        this.$log.error('Error during product search', this.$log.toString(err));
        this.growl.error('Error occurred during search');
      })
      .then((results) => !results ? [] : results);
  }

  gotoProduct(item) {
    this.searchOpen = false;
    this.$state.go('products.edit.planAnalysis.questionnaire', {productId: item.$id});
  }

  gotoDashboard() {
    if (!this.$state.includes('onboarding')) {
      this.$window.open(this.V2_URL + '/dashboard', '_self');
    }
  }

  changeOrgContext() {
    this.$uibModal
      .open({
        component: 'cfChooseOrganization',
        resolve: {
          user: () => this.user,
          titleHtml: () => '<i class="far fa-building fa-fw" aria-hidden="true"></i> Switch Organization',
          chooseMineButton: () => true,
          okButtonText: () => 'Switch to Selected',
          organizations: () => this.switchableOrgs,
        },
      })
      .result.then((organizationId) => {
        if (organizationId) {
          return this.user.setOrgContext(organizationId).then(() => {
            this.isMainOrg = organizationId === this.user.organizationId;
            this.$state.go('user.dashboard', {reload: true});
          });
        } else {
          return this.$q.resolve();
        }
      });
  }

  toCamelCase(inStr) {
    return _.camelCase(inStr);
  }

  changeDefaultSource() {
    return this.$uibModal
      .open({
        component: 'cfChangeDefaultBillingSource',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
        },
      })
      .result.then(() => {
        delete this.trialDaysLeft;

        this.growl.success('Subscription Payment Method Updated.');
      })
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return 'modalDismissed';
        }

        if (reason === 'changePlan') {
          this.checkChangeSubscription();

          return;
        }

        this.growl.error('Sorry, we were unable to process your request. Please contact customer support.');
        this.$log.error('Unable to upgrade user.', this.$log.toString(reason));
      });
  }

  changeSubscription() {
    return this.$uibModal.open({
      component: 'frPricingPage',
      size: 'xl',
      backdrop: 'static',
    });
    // return this.subscriptionService.promptForChange(this.user)
    //   .then(() => this.$window.location.reload())
    //   .catch(reason => {
    //     if (this.utils.isModalDismissalByUser(reason)) { return 'modalDismissed'; }
    //
    //     this.growl.error('Sorry, we were unable to process your upgrade request. Please contact customer support.');
    //     this.$log.error('Unable to upgrade user.', this.$log.toString(reason));
    //   });
  }

  clickPlan() {
    this.changeSubscription();
  }

  activatePlan() {
    this.subscriptionService
      .promptForActivation(this.user)
      .then(() => this.$window.location.reload())
      .catch((reason) => {
        if (this.utils.isModalDismissalByUser(reason)) {
          return 'modalDismissed';
        }
        this.utils.defaultErrorHandler(reason, 'An error occurred activating your subscription.');
      });
  }

  deleteMe() {
    return this.confirmModal({
      title: 'Hard Delete Your Account?',
      body:
        'This will hard delete your user record and, if you are the only org member, your organization. ' +
        'This cannot be undone!',
      okText: 'Delete Me',
      cancelText: 'Cancel',
    })
      .then(() => {
        this.cfpLoadingBar.start();
        return this.user.hardDelete();
      })
      .catch((err) => this.utils.defaultErrorHandler(err, 'Unable to delete user'))
      .finally(() => this.cfpLoadingBar.complete());
  }

  nav(item) {
    let upgradePromise;

    if (item.planConstraint && !this.user.doesSubscriptionPermit(item.planConstraint.constraintName)) {
      upgradePromise = this.subscriptionService.promptForUpgrade(
        this.user,
        item.planConstraint.featureName,
        item.planConstraint.requiredProductPromise
      );
    }

    this.$q
      .when(upgradePromise)
      .then(() => {
        if (item.redirectPath) {
          let url = this.V2_URL + item.redirectPath;

          this.$window.open(url, '_self');
        } else {
          this.$state.go(item.state, _.isFunction(item.paramsFn) ? item.paramsFn() : undefined);
        }
      })
      .catch((err) => {
        this.utils.defaultErrorHandler(err, 'Unable to upgrade user.');
      });
  }

  reindexEs() {
    this.selectModal({
      options: [
        {
          name: 'All Types',
          value: null,
        },
        {
          name: 'Plans',
          value: 'product',
        },
        {
          name: 'Ingredients',
          value: 'ingredient',
        },
        {
          name: 'Equipment',
          value: 'equipment',
        },
        {
          name: 'Organizations',
          value: 'organization',
        },
        {
          name: 'Suppliers',
          value: 'supplier',
        },
        {
          name: 'Supplier Ingredients',
          value: 'supplierIngredient',
        },
        {
          name: 'Orders',
          value: 'order',
        },
        {
          name: 'Users',
          value: 'user',
        },
        {
          name: 'SOPs',
          value: 'sop',
        },
        {
          name: 'Batches',
          value: 'batch',
        },
        {
          name: 'SOP Logs',
          value: 'sopLog',
        },
        {
          name: 'Leads',
          value: 'lead',
        },
        {
          name: 'SOP Library Item',
          value: 'sopLibraryItem',
        },
        {
          name: 'Recall Events',
          value: 'recallEvent',
        },
        {
          name: 'Food Products',
          value: 'foodProduct',
        },
        {
          name: 'Checklists',
          value: 'checklist',
        },
        {
          name: 'Checklist Templates',
          value: 'checklistTemplate',
        },
        {
          name: 'Batch Logs',
          value: 'batchLog',
        },
      ],
      title: 'Reindex Elastic Search Type?',
      body: 'Choose an Elastic Search data type to reindex.',
    })
      .then((choice) => {
        this.cfpLoadingBar.start();
        return this.ElasticSearchAdmin.reindex(choice);
      })
      .finally(() => this.cfpLoadingBar.complete());
  }

  getEsIndexOptions() {
    this.esIndexOptions = [
      {
        name: 'All Indexes',
        value: null,
      },
      {
        name: 'Suppliers',
        value: 'suppliers',
      },
      {
        name: 'Supplier Ingredients',
        value: 'supplierIngredients',
      },
      {
        name: 'Organizations',
        value: 'organizations',
      },
      {
        name: 'Products',
        value: 'products',
      },
      {
        name: 'Notifications',
        value: 'notifications',
      },
      {
        name: 'Ingredients',
        value: 'ingredients',
      },
      {
        name: 'Equipment',
        value: 'equipment',
      },
      {
        name: 'Orders',
        value: 'orders',
      },
      {
        name: 'Users',
        value: 'users',
      },
      {
        name: 'SOPs',
        value: 'sops',
      },
      {
        name: 'Batches',
        value: 'batches',
      },
      {
        name: 'SOP Logs',
        value: 'sopLogs',
      },
      {
        name: 'Hazards',
        value: 'hazards',
      },
      {
        name: 'Leads',
        value: 'leads',
      },
      {
        name: 'SOP Library Items',
        value: 'sopLibraryItems',
      },
      {
        name: 'Recall Events',
        value: 'recallEvents',
      },
      {
        name: 'Product Groups',
        value: 'productGroups',
      },
      {
        name: 'Food Products',
        value: 'foodProducts',
      },
      {
        name: 'Checklists',
        value: 'checklists',
      },
      {
        name: 'Checklist Templates',
        value: 'checklistTemplates',
      },
      {
        name: 'Batch Logs',
        value: 'batchLogs',
      },
    ];
  }

  hardResetEs() {
    this.selectModal({
      options: this.esIndexOptions,
      title: 'Rebuild Elastic Search Index?',
      body: 'Choose an Elastic Search data index to rebuild.',
    })
      .then((choice) => {
        this.cfpLoadingBar.start();
        return this.ElasticSearchAdmin.reset(choice);
      })
      .finally(() => this.cfpLoadingBar.complete());
  }

  switchEsIndexes() {
    this.selectModal({
      options: this.esIndexOptions,
      title: 'Switch Elastic Search Index Versions?',
      body: 'Choose the index to switch back to the previous version.',
    })
      .then((choice) => {
        this.cfpLoadingBar.start();
        return this.ElasticSearchAdmin.switchIndex(choice);
      })
      .finally(() => this.cfpLoadingBar.complete());
  }

  openSubMenu($index) {
    this.cancelCloseSubMenu($index);
    this.subMenuOpen[$index] = true;
  }

  closeSubMenu($index) {
    this.cancelCloseSubMenuMap[$index] = this.$timeout(() => this.subMenuOpen[$index] = false, 500);
  }

  cancelCloseSubMenu($index) {
    this.$timeout.cancel(this.cancelCloseSubMenuMap[$index]);
  }

  closeAllSubMenus() {
    _.each(this.subMenuOpen, (val, key) => {
      this.subMenuOpen[key] = false;
    });
  }

  chat() {
    this.drift.openSidebar();
  }

  scheduleMeeting() {
    this.drift.startInteraction(362394); // Drift: Consultation
  }

  ssoRedirect() {
    return this.authentication.getWpParams().then((result) => this.$window.location = result);
  }

  showAppDownloadModal() {
    this.$uibModal.open({
      component: 'frLogsModal',
      size: 'lg',
    });
  }

  showDemo() {
    this.$uibModal.open({
      template:
        '<iframe src="https://player.vimeo.com/video/536782314?autoplay=1" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>',
    });
  }

  sendUrlMessage() {
    return this.$uibModal
      .open({
        component: 'frSendAppUrlModal',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
        },
      });
  }

  signOut() {
    this.authentication.logout();
  }

  signIn() {
    if (this.LOCAL_DEVELOPMENT) {
      this.$state.go('authentication.signin');
    } else {
      this.$window.open(`${this.V2_URL}/signin`, '_self');
    }
  }

  checkSubscriptionId() {
    if (!this.user?.subscriptionId) {
      return this.$uibModal
        .open({
          component: 'frPricingPage',
          size: 'xl',
          backdrop: 'static',
        })
        .result.then((result) => {
          if (result === 'modalDismissed') {
            return this.$q.reject('cancel');
          }
        });
    } else {
      return this.$q.when(true);
    }
  }

  navigateToSettingsV2() {
    if (this.LOCAL_DEVELOPMENT) {
      this.$state.go('user.settings.profile');
    } else {
      this.$window.open(`${this.V2_URL}/user/settings/account`, '_self');
    }
  }
}

module.exports = Controller;
