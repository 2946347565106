module.exports = function($state, productAccess, preventDirtyNav, growl, $log, fbutil, $timeout) {
  'ngInject';

  const vm = this;

  vm.$onInit = function() {
    $timeout(function() {
      preventDirtyNav(vm.propertiesForm, 'You have unsaved changes to ' + vm.product.brandName + '.', vm.save);
    }, 0);
  };

  vm.save = function() {
    vm.product.$save()
      .then(() => {
        if (vm.propertiesForm) { vm.propertiesForm.$setPristine(); }
        growl.success('Product characteristics saved.');
      });
  };
};
